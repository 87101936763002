@import '../../../styles/_imports';

.divider {
  @include csp-breakpoint-till(xl) {
    display: none;
  }
}

.root {
  @include csp-vstack();
  gap: csp-margin(l);

  @include csp-breakpoint-up(xl) {
    @include csp-hstack($justify: space-between);
  }

  .chart {
    @include csp-hstack($align: center);
    gap: csp-margin(xl);
    flex: 1;

    .legend {
      height: 100%;
      width: 256px;
      border: solid 1px var(--col-brand-neutral-200);
      border-radius: 8px;
    }

    .donut {
      aspect-ratio: 1/1;
      flex: 1;
      max-width: 256px;
    }
  }
}
