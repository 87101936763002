@import '../../../../styles/_imports';

.popup {
  max-width: 400px;

  .confirm {
    text-align: center;
  }

  .body {
    min-height: unset;
  }

  .alert {
    color: csp-color(red);
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }
}
