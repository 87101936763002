@import '../../../../styles/_imports';

.root {
  //

  .diagram {
    flex: 1;
    min-height: 70vh;
    // min-width: 80vw;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}
