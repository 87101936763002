@import '../../styles/_imports';

.editButton {
  @include csp-vstack($align: center, $justify: center);
  @include csp-fix-size(28px);
  display: flex;
  //   padding: csp-padding(xxs) 0.375em;
  //   justify-content: center;
  //   align-items: center;
  //   gap: csp-space(xxs);
  margin-left: csp-margin(xs);

  border-radius: 50%;
  border: 1px solid csp-color(primary);
  background: transparent;
  svg path {
    fill: csp-color(primary);
  }
  //   // box-shadow: 0px 6px 8px 0px csp-color(shadow, dark), 0px 1px 1px 0px csp-color(shadow, base);
}

.editIcon {
  color: csp-color(brand-primary-500, base);
}

.cpListContainer {
  width: 100%;
  padding-bottom: csp-margin(s);
  padding-left: csp-padding(xl) !important;
}

.customName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 13.125em;
  user-select: all;
}

.noCustomName {
  font-weight: 500;
  color: csp-color(neutral-85, base);
}

.stationListRow {
  @include csp-hstack();
  width: 100%;
  font-size: csp-font-size(s);
  @include csp-padding($bottom: xs);

  @include csp-breakpoint-up(md) {
    font-size: csp-font-size(m);
  }

  .stationStatus {
    width: 10px;
    @include csp-vstack();
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .stationDataContainer {
    @include csp-ellipsis();
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    background-color: csp-color(bg-invert, lighten);
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    @include csp-padding($top: xs, $bottom: xs, $left: xs);

    @include csp-breakpoint-up(md) {
      @include csp-hstack();
    }

    .stationBasicData {
      color: csp-color(fg, base);

      @include csp-breakpoint-up(md) {
        width: 55%;
        @include csp-padding($right: l);
      }

      @include csp-breakpoint-up(lg) {
        width: 33%;
      }

      .chargeBoxId {
        @include csp-hstack($align: center);
        font-weight: bold;
        font-size: csp-font-size(m);

        @include csp-breakpoint-up(md) {
          font-size: csp-font-size(l);
        }

        @include csp-padding($bottom: xxs);

        .textLink {
          align-self: baseline;
        }
      }

      div {
        @include csp-ellipsis();
      }

      .collapseControlArea {
        border: none;
        @include csp-button;
        outline-style: none !important;
      }

      .inactive {
        cursor: default;
      }

      .collapseControlArea,
      .inactive path {
        fill: csp-color(fg, lighten);
      }
    }

    .stationExtendedDataHighlighted {
      font-weight: 700;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .stationExtendedData {
      display: none;
      color: csp-color(fg, light);

      @include csp-breakpoint-up(lg) {
        width: 33%;
        display: block;
        @include csp-padding($right: l);
      }

      div {
        @include csp-ellipsis();
      }

      .loadMgmtData {
        @include csp-hstack();
        color: csp-color(fg, base);

        .loadManagementLabel {
          font-size: csp-font-size(s);
          font-weight: bold;
        }

        .stationAndClusterLoadManagementActivationStatus {
          font-size: csp-font-size(xs);
          margin-bottom: 0;
          line-height: 25px;
        }
        .activeLoadManagement {
          color: csp-color(green, base);
        }
        .inactiveLoadManagement {
          color: csp-color(orange, base);
        }
      }
    }

    .chargePointData {
      color: csp-color(fg, light);

      @include csp-breakpoint-up(md) {
        width: 45%;
      }

      @include csp-breakpoint-up(lg) {
        width: 34%;
      }

      div {
        @include csp-ellipsis();
      }

      .chargePointBasicDataAndButton {
        @include csp-hstack($align: center);
        @include csp-margin($top: xs, $bottom: xs, $right: none, $left: none);

        @include csp-breakpoint-up(md) {
          @include csp-hstack($justify: space-between);
          @include csp-margin($top: none, $bottom: none, $right: none, $left: none);
        }

        .actions {
          @include csp-hstack();
          @include csp-margin($top: none, $bottom: none, $right: none, $left: m);

          gap: csp-margin(xxs);

          @include csp-breakpoint-up(md) {
            @include csp-margin($top: none, $bottom: none, $right: xxs, $left: none);
          }

          .chargePointSessionsButton {
            min-width: 120px !important;
            height: fit-content;
            margin: auto;
          }

          a,
          button {
            font-size: csp-font-size(xs);
            @include csp-vstack($align: center, $justify: center);
          }
        }
      }

      .outletIcons {
        flex-wrap: wrap;
        @include csp-hstack();
        @include csp-padding($top: xxs);
        justify-content: left;

        .iconAndNumber {
          @include csp-hstack();

          .outletIcon {
            height: 21px;
            width: 21px;
            @include csp-padding($right: xxs);
          }

          .outletNumber {
            @include csp-padding($right: s);
            line-height: 21px;
          }
        }
      }
    }
  }
}
