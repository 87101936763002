@import '../../../../styles/_imports';

.popup {
  max-width: 60vw;
  max-height: unset;

  .popupBody {
    > label,
    > div {
      gap: 0.5em !important;
      @include csp-breakpoint-up(md) {
        gap: 2em !important;
      }

      > div:first-child {
        @include csp-breakpoint-up(md) {
          min-width: 100px !important;
        }
      }
    }
  }

  .editEmail {
    @include csp-ellipsis();
    > div {
      @include csp-ellipsis();
    }
  }

  .passwordRow {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;

    .info {
      color: csp-color(primary, dark);
      display: table;
      max-width: 350px;
      span {
        padding-left: 0.5em;
        display: table-cell;
      }
    }

    .reset {
      color: csp-color(primary);
      display: flex;
      flex-direction: row;
      max-width: 350px;
      cursor: pointer;
      span {
        padding-left: 0.5em;
      }
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }
}
