@import '../../../../styles/_imports';

.root {
  .rolesWrapper {
    @include csp-vstack($align: flex-start);
    max-width: 350px;

    .roles {
      @include csp-vstack($align: flex-start);
      flex: 1;
      background-color: csp-color(bg-invert, light);
      border-radius: 20px;
      padding: csp-padding(m);
      margin: 5px 10px 10px 2px;
      gap: csp-margin(xs);
      overflow-y: scroll;

      &.standard {
        width: 99%;
        max-height: 200px;
      }

      &.individual {
        width: 99%;
        max-height: 80px;
      }

      &.hasValidationError {
        border: 1px solid csp-color(red);

        > label > div > input {
          border-color: csp-color(red);
        }
      }

      .warning {
        input {
          color: csp-color(warn);
          border-color: csp-color(warn);
        }
      }
    }

    .rolesMessages {
      margin-top: csp-margin(xs);
      padding-left: csp-padding(m);
      //min-height: 20px;

      .warning {
        color: csp-color(warn);
        border-color: csp-color(warn);
      }
      .error {
        color: csp-color(red);
        border-color: csp-color(red);
      }
    }
  }

  .infoIco {
    margin-left: csp-margin(xxs);
    cursor: pointer;
    svg {
      @include csp-fix-size(16px);
    }
    svg path {
      fill: csp-color(brand-primary-500, base);
    }
  }
}

// .testClass{
//   grid-column: 1 / 3;
// }
