@import '../../../../styles/_imports';

.popup {
  max-width: unset;
  max-height: unset;

  .popupBody {
    > label,
    > div {
      gap: 0.5em !important;
      @include csp-breakpoint-up(md) {
        gap: 2em !important;
      }

      > div:first-child {
        @include csp-breakpoint-up(md) {
          min-width: 100px !important;
        }
      }
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }

  .infoText {
    max-width: 350px;
    max-height: 350px;
    word-break: break-word;
    overflow: auto;
  }
}
