@import './styles/_imports';

.root {
  font-family: Roboto, -apple-system, 'Segoe UI', 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';

  display: flex;
  flex-direction: row;

  @include app-main-bg();

  --nav-collapsed-width: 96px;
  $nav-height: 72px;
  min-height: 100vh;
  .main,
  .mainInner {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    border-top-left-radius: 40px;
    min-height: 100vh;
  }
  .main {
    padding-left: var(--nav-collapsed-width);
  }
  .main.mainNoMenu {
    padding: 0;
    border-top-left-radius: 0;
    background-color: var(--col-primary-white);
  }

  .header {
    padding: var(--space-300);
    padding-left: var(--space-600);
    background-color: var(--col-primary-white);
    transition: all 0.3s;
    .headerLogo {
      // @include csp-fix-size(48px);
      max-height: 48px;
      max-width: 64px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // .hamburger{

  // }
}

:global(.menu-expanded) {
  &.root .header {
    padding-left: calc(var(--space-600) + var(--menu-width) - var(--nav-collapsed-width));
  }
}
