@import '../styles/_imports';

.root{
  &.success{
    background-color: csp-color(success);
  }
  &.danger{
    background-color: csp-color(danger);
  }
  &.warn{
    background-color: csp-color(warn);
  }
  &.primary{
    background-color: csp-color(primary);
  }
  &.info{
    background-color: csp-color(info);
  }      
  &.accent{
    background-color: csp-color(accent);
  }
  &.off{
    background-color: csp-color(off);
  }     
  &.deepPurple{
    background-color: csp-color(deepPurple);
  }     
  &.purple{
    background-color: csp-color(purple);
  }     
  
  


  @include csp-hstack($align: center, $justify: center);
  @include csp-fix-size(52px);
  border-radius: 50%;
  color: csp-color(fg-invert);
  padding: csp-padding(s);

  height: 100%;


  text-transform: uppercase;
  font-weight: light;
  font-size: csp-font-size(xs);
  > *{
    min-height: 100%;
    min-width: 100%;
  }
  path, line{
    stroke: csp-color(fg-invert);;
  }
}