@import '../styles/_imports';

.root {
  @include csp-margin($top: xxs);
  @include csp-hstack();
  gap: csp-margin(xxs);
  // flex-basis: 100%;
  // color: csp-color(primary);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  .separator {
    svg {
      @include csp-fix-size(20px);
      path {
        fill: csp-color(fg, light);
      }
    }
  }
  .step {
    text-decoration: none;
    // text-transform: uppercase;
    font-size: csp-font-size(m);
    color: csp-color(fg);

    &.active {
      font-weight: bold;
    }
  }

  .back {
    text-decoration: none;
    color: csp-color(primary);
    @include csp-hstack($align: center, $justify: center);

    svg {
      @include csp-fix-size(20px);
      path {
        fill: csp-color(primary);
      }
    }
  }
  .backSep {
    @include csp-margin($left: s, $right: s);
    font-weight: bold;
    color: csp-color(fg, lighten);
  }
}
