@import '../../styles/_imports';

.mainContainer {
  background-color: var(--col-primary-white);
  // padding: var(--space-500);
  border: solid 1px var(--col-brand-neutral-900);
  border-radius: 8px;
  // overflow: hidden
  // padding-bottom: var(--space-600);
  padding-bottom: var(--space-600);
}
