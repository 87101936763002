@import '../../styles/_imports';

.root {
  // background-color: csp-color(bg);
  gap: csp-margin(m);

  .textLink:hover {
    text-decoration: underline;
  }

  .clusterOverviewLink {
    @include csp-hstack($align: center);
    font-size: csp-font-size(xs);
    cursor: pointer;
    > svg {
      @include csp-fix-size(13px);
      path {
        fill: csp-color(fg, base);
      }
    }
    > span {
      margin-left: csp-margin(s);
      font-weight: bold;
    }
  }

  .headline {
    @include csp-hstack($justify: space-between);
    align-items: center;
    .label {
      font-weight: bold;
    }
    .workload {
      color: csp-color(fg-invert, base);
      font-weight: 700;
      margin-left: csp-margin(xs);
      padding: csp-padding(xxs);
      border-radius: 0.4em;
    }
  }

  .stationListContainer {
    width: 100%;
  }

  .tabContent {
    border-left: 1px solid csp-color(bg-invert, base);
    border-right: 1px solid csp-color(bg-invert, base);
    padding-left: csp-margin(xs);
    padding-right: csp-margin(xs);
    margin-top: -#{csp-margin(m)};
  }

  .pagination {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid csp-color(bg-invert, base);
  }
}
