@import '../../../../styles/_imports';

.popup {
  width: 60vw;
  min-height: 60vh;
  max-height: 100%;

  .rolesSection {
    @include csp-hstack($align: center);
    gap: csp-margin(m);
    padding-top: csp-margin(m);

    .fullName {
      @include csp-ellipsis();
      max-width: 40%;
      align-self: start;
    }

    .roles {
      margin-left: auto;
      gap: csp-margin(xs);
      @include csp-hstack($align: center);

      .label {
        @include csp-vstack();
        align-self: start;
        max-width: 200px;

        b {
          color: csp-color(fg, dark);
          font-weight: 500;
          min-width: 100px;
          font-size: csp-font-size(s);

          @include csp-breakpoint-up(md) {
            font-size: csp-font-size(m);
          }
        }

        span {
          margin-top: csp-margin(xxs);
          font-size: csp-font-size(xs);
          font-weight: 300;
        }

        .hasError {
          color: csp-color(red);
        }
      }

      .ctrl {
        @include csp-vstack($align: flex-start);
        flex: 1 1;
        background-color: csp-color(bg-invert, light);
        border-radius: 20px;
        width: 100%;
        max-width: 100%;
        padding: csp-padding(m);
        margin: 5px 10px 10px 2px;
        gap: csp-margin(xs);
        max-height: 180px;
        overflow: scroll;

        &.hasValidationError {
          border: 1px solid csp-color(red);

          > label > div > input {
            border-color: csp-color(red);
          }
        }
      }
    }
  }

  .footer {
    background-color: csp-color(bg-invert, light);
  }

  .actions {
    justify-content: right !important;

    button {
      max-width: 200px;
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }

  .infoIco {
    margin-left: csp-margin(xxs);
    cursor: pointer;
    svg {
      @include csp-fix-size(16px);
    }
    svg path {
      fill: csp-color(brand-primary-500, base);
    }
  }
}
