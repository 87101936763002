@import '../styles/_imports';

.root {
  //  @include csp-vstack();
  border: solid 1px var(--col-primary-neutral-050);
  border-radius: 8px;
  padding: var(--space-300);
  box-shadow: 0px 8px 8px 0px color-mix(in srgb, var(--col-brand-neutral-200) 32%, transparent), 0px 1px 1px 0px color-mix(in srgb, var(--col-brand-neutral-200) 80%, transparent);

  // margin-bottom: 80px;
  background-color: var(--col-brand-neutral-000);
}

.header {
  // @include csp-hstack();
  border-bottom: solid 1px var(--col-primary-neutral-050);
  padding-bottom: var(--space-300);
  margin-bottom: var(--space-300);
  font-weight: 500;
  font-size: 18px;
}

.expandBtn {
  @include csp-vstack($align: center);
  svg {
    transform: rotate(90deg);
    @include csp-fix-size(24px);
  }
}

.root.collapsed .content {
  display: none;
}

.collapsed .header {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;

  .expandBtn {
    transform: rotate(180deg);
  }
}

.content {
  // display: none;
  padding: var(--space-400);
  flex: 1;
}
