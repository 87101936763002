@import '../../styles/_imports';

.root {
  @include csp-padding($top: s, $bottom: s);

  .commonParent {
    > i {
      font-size: csp-font-size(xs);
      font-weight: 300;
    }
  }
}

.nodePickerFooter {
  justify-content: center !important;
  button {
    max-width: 260px;
  }
}

.nodePicker {
  width: 100%;
  min-width: 70vw;
  min-height: 70vh;
  .diagramm {
    width: 100%;
    height: 100%;

    flex: 1;
    @include csp-vstack();
  }
}

.orgSelectBar {
  @include csp-hstack($align: center);
  gap: csp-margin(m);
}

.breadcrumb {
  @include csp-hstack($align: center);
  width: 100%;
  padding: 0;
  list-style: none;
  margin: 0;
  gap: csp-margin(s);

  .breadcrumbChevron {
    &:last-child {
      display: none;
    }
    > svg {
      @include csp-fix-size(28px);
    }
  }

  .breadcrumbItem {
    flex: 1;
    padding: 0;
    // @include csp-btn();
    @include csp-padding($vertical: xs, $horizontal: m);
    border-radius: 1.5em;
    border: 4px solid transparent;

    color: csp-color(primary);
    font-weight: bolder;
    cursor: pointer;
    @include csp-ellipsis();

    &.clientRoot {
      border-color: csp-color(primary);
      &:hover {
        background-color: csp-color(primary, light);
        // color: csp-color(fg-invert);
      }
    }
    &.region {
      border-color: csp-color(orange, light);
      &:hover {
        background-color: csp-color(orange, light);
        color: csp-color(fg);
      }
    }
    &.location {
      border-color: csp-color(orange, base);
      &:hover {
        background-color: csp-color(orange, base);
        color: csp-color(fg);
      }
    }
    &.area {
      border-color: csp-color(orange, dark);
      &:hover {
        background-color: csp-color(orange, dark);
        color: csp-color(fg-invert);
      }
    }
  }
}
