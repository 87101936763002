@import '../styles/_imports';

.content {
  @include csp-hstack($align: center);
  padding: csp-padding(xs);
  padding-right: csp-padding(s);
  border: 1px solid csp-color(fg, lighten);
  border-radius: 8px;
}
.icons {
  @include csp-hstack($align: center);
}
.icon {
  display: flex;
  svg {
    overflow: visible;
  }
}
.divider {
  display: flex;
  @include csp-padding($right: s, $left: s);
}
