@import '../../styles/_imports';

.root {
  padding: var(--space-400);
  font-weight: 700;
  color: var(--col-primary-neutral-100);
  font-size: var(--fs-s);

  .pill {
    min-width: unset;
    width: 8px;
    height: 17px;
  }

  .statsRow.hide {
    visibility: hidden;
  }

  .titleValue.blurValue {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .tooltip {
    max-width: 400px;
    opacity: 1 !important;
  }
}
