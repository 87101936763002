@import '../styles/_imports';

.progressContainer {
  width: 100%;
  // height: 20px;
  border-radius: 8px;
  overflow: hidden;
  font-weight: bold;
  background-color: var(--col-primary-neutral-020);

  .progress {
    height: 100%;
    @include csp-vstack($align: center, $justify: center);
    background-color: var(--col-functional-disabled-200);

    &.green-kelly-green,
    &.dataviz-blue-medium-persian {
      color: var(--col-brand-neutral-100);
    }
  }
}

.stack {
  @include csp-hstack;
  border-radius: 0.2em;
  overflow: hidden;
  background-color: var(--col-primary-neutral-020);

  .progressContainer {
    width: auto;
    border-radius: unset;

    .progress {
      width: 100%;
    }
  }
}
