@import '../styles/_imports';

.root{
 
  // width: 80px;
  // height: 80px;
  width: 100%;
  height: 100%;
  
  
  &.autoSize{
    > div, > div > div{
      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
      min-height: 100%;
      min-width: 100%; 
  
      width: 100% !important;
      height: 100% !important;

      img{
        object-fit: cover;

        max-width: 100%;
        height: auto !important;         
        width: unset !important;
      }
    }
  }

}