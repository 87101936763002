@import '../../styles/_imports';

$ylblWidth: 60px;

.legend {
  border: solid 1px var(--col-brand-neutral-200);
  border-radius: 8px;
}

.donutEnergyValue {
  font-weight: 600;
  color: var(--col-atenso-blue);
  padding: var(--space-300);
}

.donut {
  height: 260px;
  width: 260px;
  aspect-ratio: 1/1;
  > div {
    background-color: var(--col-brand-neutral-050);
    padding: 20px;
    border-radius: 50%;
    > div {
      background-color: var(--col-brand-neutral-000);
      @include csp-common-shadow();
      border-radius: 50%;
      padding: 14px;
      // some hacks to ovverride donut style, cause it is not the default vizualized dout as in other pages
      > div > div {
        padding: 0 !important;
      }
    }
  }
}

.totalSumBar {
  min-height: 56px;
}

.datePicker {
  margin-bottom: csp-margin(s);
  @include csp-breakpoint-up(sm) {
    max-width: 320px;
    margin-left: auto;
  }
}
