@import '../../../styles/_imports';

.popup {
  width: 40vw;
  max-width: 50vw;

  .tagSearchContainer {
    @include csp-vstack();
  }

  .tagSearchDisplay {
    @include csp-hstack();
    flex-wrap: wrap;
    gap: csp-margin(xs);

    .appliedTag {
      background-color: csp-color(accent);
    }
  }

  .tagSearchInput {
    :global(.tagSelectPref__value-container) {
      flex-wrap: nowrap;
    }

    :global(.tagSelectPref__multi-value__label) {
      padding: 0;
    }

    .stationTag {
      @include csp-padding($horizontal: xs);
      @include csp-hstack($align: center);
      color: csp-color(fg-invert);
      height: 100%;
      background-color: csp-color(accent);
    }

    .stationOpt {
      @include csp-vstack();

      .type {
        font-size: csp-font-size(xs);
        color: csp-color(accent);
      }
    }
  }

  .tagSearchDisplay {
    @include csp-hstack();
    flex-wrap: wrap;
    gap: csp-margin(xs);

    .stationAppliedTag {
      background-color: csp-color(accent);
    }
  }
}
