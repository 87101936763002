@import '../../styles/_imports';

.appliedTags {
  @include csp-hstack();
  margin-bottom: csp-margin(xs);
  .tag {
    margin-right: csp-margin(xs);
    background-color: csp-color(bg-inactive, base);
    color: csp-color(fg-inactive, base);
    svg path {
      stroke: csp-color(fg-inactive);
    }
  }
}
