@import '../styles/_imports';

@import '../styles/_imports';

// .errorLabel {
//   display: flex !important;
//   padding-top: csp-padding(xxs);
//   color: csp-color(red);
//   font-size: 12px;
//   @include csp-hstack($align: center);
//   gap: csp-margin(xs);
//   .warnIco > svg {
//     @include csp-fix-size(18px);
//   }
// }
.errorLabel {
  color: var(--col-functional-error-full);
}
.formContainer {
  @include csp-vstack();

  // gap: csp-margin(xxs);
  gap: var(--space-200);
  .lblWrapper {
    // font-size: csp-font-size(s);
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 400;
    color: var(--col-primary-neutral-085);
  }

  &.hasValidationError {
    .lblWrapper {
      color: csp-color(danger);
    }
    .input input {
      border-color: csp-color(danger);
    }
  }
}
.input {
  box-sizing: border-box;
  @include csp-hstack($align: center);
  gap: var(--space-100);
  border-radius: 8px;
  // substract the border
  padding: calc(var(--space-200) - 1px) var(--space-300);
  width: 100%;
  font-size: var(--fs-s);
  line-height: var(--lh-xs);
  border: solid 1px var(--col-primary-neutral-080);
  background-color: var(--col-primary-white);

  > input {
    flex: 1;
    border: none;
    outline: none !important;
    color: var(--col-brand-neutral-900);
    font-weight: 500;
    padding: 0;

    &::placeholder {
      font-weight: 400;
      color: var(--col-primary-neutral-100);
    }
  }

  // &:focus-within {
  //   > input {
  //     color: csp-color(fg, dark);
  //   }
  //   > .lblWrapper > span {
  //     color: csp-color(accent);
  //   }
  // }
}
