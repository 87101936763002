@import '../styles/_imports';

.root {
  color: var(--col-primary-500);
  width: fit-content;
  @include csp-hstack($align: center, $justify: center);

  &.stroke svg {
    path {
      stroke: currentColor;
    }
  }
  &.fill svg {
    path {
      fill: currentColor;
    }
  }
}

.circle {
  aspect-ratio: 1/1;

  min-width: 13px;
  max-width: 13px;
  min-height: 13px;
  max-height: 13px;
  background-color: var(--col-brand-neutral-900);
  border-radius: 50%;
}
