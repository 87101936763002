@import '../../../../styles/_imports';

.permissionsLbl {
  padding-bottom: csp-padding(s) !important;
}

.accordionHeadline {
  @include csp-hstack($align: center, $justify: space-between);
}

.permissionsGroupList {
  @include csp-vstack($align: center);
  gap: csp-margin(m);
  padding: csp-padding(s) 2px;
  margin: 0px csp-padding(s);
}

.permissionsGroup {
  @include csp-vstack();
  gap: csp-margin(m);
  padding-left: csp-padding(l);

  &.hasValidationError {
    > label > div > input {
      border-color: csp-color(red);
    }
  }

  .warning {
    input {
      color: csp-color(warn);
      border-color: csp-color(warn);
    }
  }
}

.permissionMessages {
  padding-left: csp-padding(m);
  min-height: 20px;

  .warning {
    color: csp-color(warn);
    border-color: csp-color(warn);
  }

  .error {
    color: csp-color(red);
    border-color: csp-color(red);
  }
}
