@import '../../../styles/_imports';

.diagram {
  height: 100%;
  min-height: 50vh;
}

.diagramContainer {
  min-height: 50vh;
}

.tabContent {
  padding: 0 0 var(--space-400) 0 !important;
  background-color: white;
}

.subHeader > div {
  background-color: transparent !important;
  border: none !important;
}
.selStationsArea {
  padding: var(--space-200);
  background-color: #d6efff;
  border-radius: 8px;
  margin-top: var(--space-200);
  border: solid 1px var(--col-brand-neutral-200);

  .selStationRow {
    background-color: var(--col-primary-white);
    border-radius: 8px;
    border: solid 1px var(--col-brand-neutral-200);
    @include csp-common-shadow();

    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr;
  }
}

.nodePickerFooter {
  justify-content: center !important;
  button {
    max-width: 260px;
  }
}

.submitError {
  padding-left: 2px;
  color: csp-color(red);
  border-color: csp-color(red);
}
