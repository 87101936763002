@import '../../styles/_imports';

.pageBtn {
  border-radius: 50%;
  aspect-ratio: 1/1;

  font-size: var(--fs-s);
  line-height: var(--lh-xs);

  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;
  background-color: var(--col-primary-white);
  color: var(--col-primary-black);
  border: solid 1px var(--col-primary-500);

  display: flex;
  justify-content: center;
  align-items: center;

  &.ellipsis {
    border: none;
    background-color: transparent;
    cursor: default;
    // color: var(--col-primary-500);
  }
  &.active {
    background-color: var(--col-primary-500);
    color: var(--col-primary-white);
    // border: none;
  }
}

.pagingation select {
  border-radius: 6px;
  border: solid 1px var(--col-primary-neutral-080);
  padding: 0.5em;
  padding: var(--space-100) var(--space-200);
}
