@import '../../../../styles/_imports';

.root {
  .filterCard {
    @include csp-hstack();
    gap: csp-margin(m);
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }

  .row {
    padding: csp-padding(xs);
  }

  .fakeRow {
    @include csp-vstack();
    gap: csp-margin(m);
    width: 100%;

    @include csp-breakpoint-up(md) {
      @include csp-hstack($align: center, $justify: space-between);
    }
  }

  .affiliateLbls {
    @include csp-vstack();

    b {
      font-weight: normal;
      word-break: break-all;
    }

    i {
      font-style: normal;
      // font-weight: bold;
      font-size: csp-font-size(xs);
    }
  }

  .actions {
    @include csp-hstack();
    gap: csp-margin(s);

    .btn {
      font-size: 80%;
      min-width: 120px !important;
      max-height: 42px;

      &.icoBtn {
        min-width: auto !important;
        background-color: transparent;
        border: none;
      }
    }
  }
}

.deletePopupRow {
  max-width: 420px;

  &.alert {
    color: csp-color(red);
  }
  &.warn {
    color: csp-color(yellow);
  }
}
