@use 'sass:math';
@import '../../styles/_imports';

.root {
  height: 100%;
  width: 100%;

  @include csp-hstack($justify: space-between);

  overflow: hidden;
  $yLblHeight: 20px;

  .yLbl {
    @include csp-vstack();
    justify-content: space-between;
    margin-bottom: $yLblHeight;
    font-size: csp-font-size(xs);
    text-align: right;

    border-right: 2px solid var(--col-red-light-salmon);
    color: var(--col-red-light-salmon);
    padding-right: var(--space-300);
  }

  &.noXAxis {
    .yLbl {
      margin-bottom: 0;
    }
  }

  .barArea {
    @include csp-hstack($justify: space-around);
    flex: 1;
    gap: var(--space-300);
    border-top: 2px solid var(--col-red-light-salmon);
    padding-left: var(--space-300);
  }

  .barGroup {
    @include csp-vstack();
    min-width: 6px;
    flex: 1;
    height: 100%;

    .xLbl {
      height: $yLblHeight;

      overflow-x: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: csp-font-size(xxs);

      padding-top: var(--space-200);

      margin-left: calc(var(--space-400) * -1);
      margin-right: calc(var(--space-400) * -1);
    }

    .bars {
      $radius: 6px;
      // height: calc(100% - $lblHeight);
      flex: 1;
      width: 100%;
      overflow-y: hidden;
      height: 100%;
      // background-color: var(--col-brand-neutral-200);
      border: solid 1px var(--col-functional-disabled-200);

      @include csp-vstack($justify: flex-end);

      // @include csp-neumorph-in();
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;

      .barsInner {
        overflow: hidden;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
        @include csp-vstack($justify: flex-end);
      }

      .bar {
        transition: height 0.6s ease-in-out;
        height: 0%;
        width: 100%;
      }
    }
  }
}
