@import '../../../../styles/_imports';

.select {
  width: 188px;
}
.root {
  .tableArea {
    @include csp-ellipsis();
  }
  .nameCol {
    @include csp-hstack($align: center);
    width: 100%;
    gap: csp-margin(s);
    > div {
      @include csp-fix-size(36px);
    }
    > span {
      flex: 1;
    }
  }

  .emailCol {
    @include csp-hstack();
    gap: csp-margin(xs);
    .title {
      overflow: hidden;
    }
    .ico {
      margin-left: csp-margin(xs);
      svg {
        @include csp-fix-size(16px);
      }
      svg path {
        fill: csp-color(fg);
        stroke: csp-color(fg);
      }
    }
  }

  .appliedFilterArea {
    @include csp-hstack();
    gap: csp-margin(xxs);
    .filteredRole {
      background-color: csp-color(off, light);
      color: csp-color(fg-inactive);
      max-width: 116px;
      svg path {
        stroke: csp-color(fg-inactive);
      }
      // @include csp-ellipsis();
    }
  }

  .role {
    @include csp-hstack();
    .roleName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .infoIco {
      margin-left: csp-margin(xxs);
      cursor: pointer;
      svg {
        @include csp-fix-size(16px);
      }
      svg path {
        fill: csp-color(brand-primary-500, base);
      }
    }
  }

  .roleKinds {
    @include csp-hstack();
    gap: csp-margin(xxs);
    > span {
      font-size: csp-font-size(xs);
    }
  }

  .clientItem {
    @include csp-ellipsis();
  }

  .expandItem {
    gap: csp-margin(xxs);
    margin-top: csp-margin(xs);
    font-weight: bold;
    cursor: pointer;
    white-space: normal;
  }

  .actions {
    @include csp-hstack();
    gap: csp-margin(s);
    margin-left: auto;
    .editBtn {
      @include csp-breakpoint-up(md) {
        min-width: 100px;
      }
      @include csp-breakpoint-up(lg) {
        min-width: 140px;
      }
    }
    .icoBtn {
      @include csp-btn();
      min-width: auto !important;
    }
  }

  .filters {
    @include csp-hstack();
    gap: csp-margin(s);
    flex-wrap: wrap;

    .roleKinds {
      @include csp-hstack();
      gap: csp-margin(s);
    }

    .roleSelect {
      width: 250px;
    }
  }
}
