@import '../styles/_imports';

.root {
  @include csp-padding($top: xs, $bottom: xs, $left: s, $right: s);
  min-width: 80px;
  border: solid 1px csp-color(primary);
  border-radius: csp-padding(l);
  transition: 400ms;

  svg path {
    fill: csp-color(primary);
    transition: 400ms;
  }

  &.allignRight {
    margin-left: auto;
  }

  &.primary {
    background-color: csp-color(primary);
    color: csp-color(fg-invert);

    svg path {
      fill: csp-color(fg-invert);
    }

    &:hover {
      background-color: csp-color(primary, dark);
      border-color: csp-color(primary, dark);
    }
  }

  &.accent {
    background-color: transparent;
    color: csp-color(primary);

    svg path {
      fill: csp-color(primary);
    }

    &:hover {
      background-color: csp-color(primary, light);
      border-color: csp-color(primary, light);
    }
    &.disabled:hover {
      background-color: transparent;
      color: csp-color(primary);
    }
  }

  &.icon {
    background-color: transparent;
    border-color: transparent;
    min-width: auto;

    svg path {
      fill: csp-color(primary);
    }

    &:disabled {
      svg path {
        fill: csp-color(fg, lighten);
      }
    }
  }

  &.warn {
    background-color: transparent;
    color: csp-color(warn);
    border-color: csp-color(warn);

    svg path {
      fill: csp-color(warn);
    }

    &:hover {
      background-color: csp-color(warn);
      color: csp-color(fg-invert);
    }
  }

  &.danger {
    background-color: transparent;
    color: csp-color(danger);
    border-color: csp-color(danger);

    svg path {
      fill: csp-color(danger);
    }

    &:hover {
      background-color: csp-color(danger);
      color: csp-color(fg-invert);
    }
  }

  &:hover {
    text-decoration: none;
    // svg path{
    //   fill: csp-color(fg-invert);
    // }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

a.root {
  text-decoration: none;
  text-align: center;
}
