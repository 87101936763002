@import '../../../styles/_imports';

.root {
  .headerArea {
    @include csp-hstack();
    gap: csp-margin(m);
    flex-wrap: wrap;
    margin-bottom: csp-margin(l);

    .jobInfo {
      flex: 1;
      padding: csp-padding(s);

      table {
        border-collapse: collapse;
        width: 100%;
        max-width: 500px;

        td {
          border: 1px solid csp-color(fg-inactive);
          padding: csp-padding(xxs);
        }

        td:first-child {
          width: 10em;
        }

        tr:nth-child(odd) {
          background-color: csp-color(bg, darken);
        }
      }
    }
  }

  .tableArea {
    .row {
      align-items: flex-start;
    }
  }
}
