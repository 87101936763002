@import '../styles/_imports';

.root{
  @include csp-vstack();

  .tabs{
    @include csp-hstack();
    gap: csp-margin(xxs);

    .tab{
      flex: 1;
      @include csp-hstack($align: center, $justify: center);
      gap: csp-margin(xs);
      color: csp-color(primary);
      font-weight: bold;
      padding: csp-padding(s);
      background-color: csp-color(bg-invert);

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      cursor: pointer;

      &.active{
        background-color: csp-color(bg-invert, lighten);
      }

      &.fillIco{
        svg path{
          fill: csp-color(primary);
        }
      }
      &.strokeIco{
        svg path{
          stroke: csp-color(primary);
        }
      }      

      &.lalign{
        justify-content: flex-start;
      }


      .txt{
        display: none;
        @include csp-breakpoint-up(md){
          display: initial;
        }
      }
      .ico{
        > svg{
          @include csp-fix-size(36px);
        }
      }
    }
  }

  .tabContent{
    display: none;
    background-color: csp-color(bg-invert, lighten);
    // &.noBg{
    //   background-color: transparent;
    // }
    padding: csp-padding(m);
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &.active{
      display: block;
    }
  }  
}