@import '../../styles/_imports';

.root {
  .clearBtn {
    color: var(--col-brand-neutral-300);
    text-decoration: underline;
    cursor: pointer;
    svg {
      @include csp-fix-size(18px);
      path {
        stroke: var(--col-primary-500);
      }
    }
  }
  button.btn {
    border-radius: 50%;
    border: solid 1px var(--col-primary-500);
    padding: 0;
    @include csp-fix-size(32px);
    @include csp-vstack($align: center, $justify: center);

    svg {
      @include csp-fix-size(18px);
    }
  }
}
