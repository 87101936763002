@import '../../styles/_imports';

.documentFileName {
  z-index: 1;
}
.documentType {
  margin-top: -8px;
}
.expandBtn {
  min-width: fit-content;
}

.modalFooter {
  button {
    // width: 180px;
    // > div{
    //   justify-content: center;
    // }
    // text-align: center;
  }
}

.uploadArea {
  height: 200px;
}
