@import '../../../../styles/_imports';

.root {
  @include csp-vstack();
  gap: csp-margin(m);
  flex: 1;

  .diagram {
    flex: 1;
    min-height: 50vh;
    width: 100%;
    height: 100%;
  }

  .header {
    padding-bottom: csp-margin(xxs);
    border-bottom: 1px solid csp-color(fg, lighten);
  }

  .footer {
    @include csp-vstack();
    gap: csp-margin(m);
    margin-bottom: csp-margin(s);

    .error {
      padding-left: 2px;
      color: csp-color(red);
      border-color: csp-color(red);
    }

    .legend {
      @include csp-hstack($align: center);
      gap: csp-margin(l);

      span {
        @include csp-hstack($align: center);
        gap: csp-margin(xs);
      }
    }
  }
}
