@import '../styles/_imports';

.contextMenu {
  z-index: 100001;
}
.hideOnMd {
  display: none;
  &.hideOnLg {
    display: none;
  }
}

.lgMoreLink {
  display: none;
}

.nav {
  display: none;
}
.hamburgerCol {
  margin-left: auto;
}
.usrCol {
  display: none;
}

.devDistinctionBorder {
  outline: 6px solid csp-color(red, base);
}

@include csp-breakpoint-up(lg) {
  .mdMoreLink {
    display: none;
  }
  .hideOnMd {
    display: block;
    &.hideOnLg {
      display: none;
    }
  }
  .lgMoreLink {
    display: block;
  }
}
@include csp-breakpoint-up(md) {
  .hamburgerCol {
    display: none;
  }
  .usrCol {
    display: block;
  }
  .nav {
    @include csp-hstack($align: center);
  }
}

.userProfileIcon {
  padding-right: csp-padding(xs);
}

.contextLink {
  &:hover {
    background-color: csp-color(primary);
    a {
      color: csp-color(fg-invert);
    }
  }
  a {
    text-decoration: none;
    font-weight: 600;
    font-size: csp-font-size(s);
    color: csp-color(fg);
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 6px 12px;
  }

  > div {
    color: csp-color(fg);
    padding: 0;
  }
  &.contextLinkMuted > div {
    color: csp-color(fg, light);
  }
}

.icoBtn {
  @include csp-hstack($align: center);
  border: none;
  color: csp-color(accent);
  background-color: transparent;
  gap: csp-margin(xxs);

  svg {
    @include csp-fix-size(24px);
  }
}

.root {
  @include csp-hstack($align: center);
  z-index: 100;
  max-height: 72px;

  .logoCol {
    height: 100%;
    @include csp-padding($left: l, $right: l, $top: xs, $bottom: xs);
    > img {
      display: block;
      height: 100%;
      width: auto;
      max-height: 100%;
      min-width: 74px;
    }
  }

  .nav {
    gap: csp-margin(m);
    height: 100%;
    margin-right: auto;

    .mdMoreLink,
    .lgMoreLink {
      padding: 0;

      svg {
        transform: rotate(-90deg);
        transition: transform 0.4s linear;
        path {
          stroke: csp-color(accent);
        }
      }
      &.moreLinkOpen {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .link {
      text-decoration: none;
      font-weight: 600;
      font-size: csp-font-size(s);
      color: csp-color(fg, light);
      border: none;
      background-color: transparent;
      // border-bottom: 2px solid transparent;
      cursor: pointer;
      position: relative;

      span::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: csp-color(accent);
        left: 0;
        bottom: -6px;
        opacity: 0;
        transition: opacity linear 0.4s;
      }

      &:hover,
      &.active {
        span::after {
          opacity: 1;
        }
      }
    }
  }

  .usrCol {
    margin-left: csp-margin(xs);
    margin-right: csp-margin(m);
    > span {
      cursor: pointer;
      img {
        border: 2px solid csp-color(accent);
      }
    }
  }
}

.appSwitchPopup {
  // max-width: 620px;
  section {
    @include csp-padding($horizontal: l);
    padding-top: csp-padding(m);
    @include csp-vstack($align: center);
    h3,
    h6 {
      text-align: center;
    }
    // min-width: 80vw;
  }
  .tiles {
    margin-top: csp-margin(l);
    @include csp-hstack($align: center, $justify: center);
    gap: csp-margin(m);
    flex-wrap: wrap;
    .tile {
      width: 200px;
      background-color: csp-color(bg, dark);
      padding: csp-padding(m);
      border-radius: 0.5em;
      cursor: pointer;
      .body {
        @include csp-hstack();
        .caption {
          @include csp-vstack();
          flex: 1;
        }
        .ico {
          svg {
            @include csp-fix-size(28px);
            path {
              fill: csp-color(fg, light);
            }
          }
        }
      }
      .footer {
        font-size: csp-font-size(s);
        span {
          text-decoration-line: underline;
          text-decoration-style: dotted;
        }
      }
    }
    .tile.changable {
      svg {
        path {
          fill: csp-color(red) !important;
        }
      }
      &:hover {
        background-color: csp-color(bg);
        box-shadow: 0px 0px 5px -1px csp-color(fg);
      }
    }
  }
}
