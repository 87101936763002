@import '../../styles/_imports';

.root {
  .charts {
    background-color: var(--col-primary-white);
    padding: var(--space-500);
    border: solid 1px var(--col-brand-neutral-900);
    border-radius: 8px;
  }

  .totalEnergyConsumption {
    background-color: var(--col-brand-neutral-050);
    padding: var(--space-300);
    text-align: center;
    font-weight: 500;
  }
}
