@import '../styles/_imports';

$inputPaddingTB: csp-padding(xxs);
$inputPaddingLR: csp-padding(xs);
$inputBorderWidth: 1px;

.container {
  position: relative;
  width: 100% !important;
}

.input {
  border-radius: 3px;
  border: $inputBorderWidth solid csp-color(fg, light);
  padding: $inputPaddingTB $inputPaddingLR;
  width: 100%;

  // @include csp-padding($left: xs, $right: xs, $top: xxs, $bottom: xxs);
  outline: none !important;
  color: csp-color(fg);
  font-weight: 400;

  border-image-slice: 1;
  background-image: linear-gradient(transparent, transparent);   
}

.inputFocused {
  outline: none;
}

.inputOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestionsContainer {
  display: none;
  width: 100% !important;
  position: absolute;
  // top: 51px;
  // top: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 20;  
}

.suggestionsContainerOpen {
  display: block;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  @include csp-padding($horizontal: xs, $vertical: xxs);
  font-size: csp-font-size(s);
  border-bottom: csp-border-default();
}

.suggestionHighlighted {
  background-color: csp-color(primary);
  color: csp-color(fg-invert);
}
