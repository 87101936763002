@import '../../styles/_imports';

.root {
  //

  .settingsArea {
    @include csp-hstack();
    gap: csp-margin(xxl);

    border-bottom: 1px solid csp-color(bg-invert);
    padding-bottom: csp-padding(m);
    margin-bottom: csp-margin(m);

    .settingsRegion {
      @include csp-vstack;

      h4 {
        font-size: csp-font-size(s);
      }
    }

    .languages,
    .translationInspection {
      @include csp-hstack();
      gap: csp-margin(s);
    }

    .deLang {
      background-color: csp-color(orange);
    }

    .enLang {
      background-color: csp-color(deepBlue);
    }

    .activeChecked {
      background-color: csp-color(green);
    }
    .activeUnChekced {
      background-color: csp-color(bg);
      outline: 1px solid csp-color(green);
      color: csp-color(green);
    }

    .inactiveChecked {
      background-color: csp-color(red);
    }
    .inactiveUnChekced {
      background-color: csp-color(bg);
      outline: 1px solid csp-color(red);
      color: csp-color(red);
    }

    .missingTranslationEnabled {
      background-color: csp-color(green);
    }

    .missingTranslationDisabled {
      background-color: csp-color(red);
    }
  }

  .valCol {
    @include csp-vstack();
    .langRow {
      @include csp-hstack();
      gap: csp-margin(xxs);
    }
  }
  .statusCol {
    border-right: 16px solid transparent;

    &.translated {
      border-right-color: csp-color(success);
    }

    &.nontranslated {
      border-right-color: csp-color(danger);
    }
    &.obsolete {
      border-right-color: csp-color(off);
    }

    // .activeCol {
    //   color: csp-color(success);
    // }

    // .inactiveCol {
    //   color: csp-color(danger);
    // }
  }
}
