@import '../../styles/_imports';

.multiStepPopup {
  width: 680px;
  min-height: 420px;
}
.selectedTag {
  // height: 90px;
  font-size: 16px;
  padding: var(--space-500) var(--space-400);
}

.dropdown,
.selectedTag {
  min-width: 200px;
  justify-content: space-between;
}

.availabilityMode {
  @include csp-hstack($align: center, $justify: space-around);
  width: 100%;
}
