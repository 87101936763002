@import '../styles/_imports';

.root{
  @include csp-padding($all: xxs);
  padding-left: csp-padding(xxs);
  padding-right: csp-padding(xxs);
  align-self: flex-start;
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 3px;

  &.clickable{
    cursor: pointer;
  }

  &.selected{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);    
  }
}

.success{
  background-color: lighten($color: csp-color(success), $amount: 35);
  color: darken($color: csp-color(success), $amount: 30);
}
.danger{
  background-color: lighten($color: csp-color(danger), $amount: 45);
  color: darken($color: csp-color(danger), $amount: 20);
}
.warn{
  background-color: lighten($color: csp-color(warn), $amount: 35);
  color: darken($color: csp-color(warn), $amount: 25);
}
.primary{
  background-color: lighten($color: csp-color(primary), $amount: 45);
  color: darken($color: csp-color(primary), $amount: 20);        
}
.info{
  background-color: lighten($color: csp-color(info), $amount: 40);
  color: darken($color: csp-color(info), $amount: 20);
}      
.accent{
  background-color: lighten($color: csp-color(accent), $amount: 40);
  color: darken($color: csp-color(accent), $amount: 20);
}
.off{
  background-color: lighten($color: csp-color(off), $amount: 40);
  color: darken($color: csp-color(off), $amount: 20);
}
.deepPurple{
  background-color: lighten($color: csp-color(deepPurple), $amount: 40);
  color: darken($color: csp-color(deepPurple), $amount: 20);    
}     
.purple{
  background-color: lighten($color: csp-color(purple), $amount: 40);
  color: darken($color: csp-color(purple), $amount: 20);    
}   



