@import '../../../../styles/_imports';

.popup {
  min-width: 75vw;
  width: 75vw;
  max-height: unset;
  max-width: unset;

  @include csp-breakpoint-up(lg) {
    width: unset;
  }

  .langRow {
    z-index: 5;
  }
  .inputField {
    min-width: 100px;
    max-width: 350px;

    @include csp-breakpoint-up(md) {
      min-width: 200px;
    }
  }

  .popupBody {
    > label,
    > div {
      gap: 0.5em !important;
      @include csp-breakpoint-up(md) {
        gap: em !important;
      }

      > div:first-child {
        min-width: 150px !important;
      }
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }
}
