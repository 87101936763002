@import '../styles/_imports';


.root{
  display: inline-flex;
  gap: csp-margin(l);
  flex-wrap: wrap;

  .card{
    @include csp-padding($top: xs, $bottom: xs, $left: m, $right: m);
    width: 100%;

    border-radius: 10px;
    background-color: csp-color(bg);
    border: 1px solid csp-color(primary);
    cursor: pointer;

    @include csp-breakpoint-up(md){
      width: calc(50% - #{csp-margin(m)});
      // @include csp-margin($right: m);
    }
    @include csp-breakpoint-up(lg){
      width: calc(33% - #{csp-margin(m)});
      // @include csp-margin($right: m);
    }

    .header, .body{
      font-size: csp-font-size(m);
    }
    .body{
      color: csp-color(fg, light);
    }

    &:hover{
      background-color: csp-color(primary);
      color: csp-color(fg-invert);
      .body{
        color: csp-color(fg-invert);
      }
    }



  }  
}