@import '../../styles/_imports';

.cpListRow {
  @include csp-hstack();
  margin-top: csp-margin(xs);
  border-right: 2px solid transparent;

  .cpStatus {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    flex: 0 0 13px;
  }
  .noCustomName {
    font-weight: 500;
    color: csp-color(neutral-85, base);
  }

  .editButton {
    @include csp-vstack($align: center, $justify: center);
    @include csp-fix-size(28px);
    display: flex;
    margin-left: csp-margin(xs);

    border-radius: 50%;
    border: 1px solid csp-color(primary);
    background: transparent;
    svg path {
      fill: csp-color(primary);
    }
    //   // box-shadow: 0px 6px 8px 0px csp-color(shadow, dark), 0px 1px 1px 0px csp-color(shadow, base);
  }

  .editIcon {
    color: csp-color(brand-primary-500, base);
  }

  .cpData {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(max(135px, 40%)), 1fr));
    grid-gap: csp-margin(xs);
    padding-top: csp-margin(xs);
    padding-left: csp-padding(xs);
    background-color: csp-color(bg-invert, lighten);
    flex: 1 1 100%;

    * {
      @include csp-ellipsis();
    }

    @include csp-breakpoint-up(md) {
      grid-template-columns: 2fr 1fr 1.7fr 1.5fr;
      grid-template-rows: none;
      & > div {
        grid-row: unset !important;
      }
    }
    @include csp-breakpoint-up(lg) {
      grid-template-columns: 2fr 1.2fr 1.6fr 1.2fr 1.4fr;
    }
    .evseId {
      font-weight: bold;
    }

    .cpBaseData {
      .statusText {
        font-size: csp-font-size(s);
        line-height: csp-margin(m);
        height: csp-margin(m);
        background-color: unset;
      }
      .evseId {
        @include csp-hstack($align: center);
        font-size: csp-font-size(l);
        font-weight: bold;
      }
      .textLink {
        cursor: pointer;
        @include csp-hstack($align: center);
        &:hover {
          text-decoration: underline;
        }
      }
      grid-column: 1 / -1;
      @include csp-breakpoint-up(md) {
        grid-column: unset !important;
      }
    }

    .cpExtendedData {
      .plugTypeImage {
        width: 20px;
        height: 20px;
        margin-right: csp-margin(xxs);
      }
    }

    .cpCurrentState {
      height: 100%;
      display: flex;
      .cpCurrentPower {
        display: flex;
        align-items: center;
        border-radius: 2em;
        height: 23px;
        width: 130px;
        padding: csp-margin(xs);
        padding-right: csp-margin(m);
        padding-left: csp-margin(m);
        background-color: csp-color(deepBlue, light);
        color: csp-color(deepBlue, base);
        .flashIconAndDescription {
          margin-right: csp-margin(s);
        }
        .flashIcon {
          width: 15px;
          height: 20px;
          path {
            fill: csp-color(deepBlue, base);
          }
        }
        .lockIcon {
          width: 16px;
          height: 20px;
        }
        .clockIcon {
          width: 20px;
          height: 20px;
        }
      }
      .reserved,
      .locked {
        background-color: csp-color(bg-invert, base);
        justify-content: center;
      }
      .reduced {
        color: csp-color(fg, base);
        background-color: csp-color(yellow, base);
        .flashIcon {
          path {
            fill: csp-color(fg, base);
          }
        }
      }
    }
  }

  .cpLoadManagement {
    .textLink {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      list-style-position: inside;
      padding-left: 0;
    }
    > span {
      display: block;
      font-size: csp-font-size(s);
    }
    .cpLoadManagementLabel {
      font-weight: bold;
    }
    .cpLoadManagementActivationStatus {
      font-size: csp-font-size(xs);
    }
    .activeLoadManagement {
      color: csp-color(green, base);
    }
    .inactiveLoadManagement {
      color: csp-color(orange, base);
    }
    @include csp-breakpoint-up(md) {
      display: none;
    }
    @include csp-breakpoint-up(lg) {
      display: unset;
    }
  }

  .cpButtonsAndMore {
    @include csp-hstack($align: baseline);
    overflow: visible;
    white-space: nowrap;
    padding-bottom: csp-margin(xxs);
    @include csp-breakpoint-up(md) {
      justify-content: flex-end;
    }

    .btnSessions {
      font-size: csp-font-size(xs);
      min-width: 120px !important;
    }

    .btnDots {
      padding-left: 0 !important;
    }
  }
}
