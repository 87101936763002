@import '../../styles/_imports';

.root {
  //

  .btnS {
    @include csp-padding($vertical: xxs);
  }
  .refreshMsgCntBtn {
    margin-left: auto;
  }

  .tile {
    border: 1px solid csp-color(bg-invert);
    padding: csp-padding(m);
    border-radius: 0.5em;
    @include csp-vstack();
    gap: csp-margin(s);
  }

  .evseid {
    margin-top: csp-padding(m);
  }
}

.diagram {
  flex: 1;
  min-height: 70vh;
  min-width: 80vw;
  width: 100%;
  height: 100%;
}
.vstack {
  @include csp-vstack();
  gap: csp-margin(s);
}

.actions {
  flex: 1;
  @include csp-hstack($align: center, $justify: space-between);
  gap: csp-margin(s);
  margin: csp-margin(m);
  button {
    flex: 1;
  }
}

.assignPopup {
  width: 800px;
}
