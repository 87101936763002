@import '../../styles/_imports';

.root {
  &.expanded {
    .display {
      border: solid 1px var(--col-brand-neutral-900);
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: var(--col-primary-neutral-010);
      font-weight: 700;

      svg {
        transform: rotate(-90deg);
      }
    }
    .inputsWrapper {
      border: solid 1px var(--col-brand-neutral-900);
      border-top: none;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  .display {
    height: 32px;
    width: 200px;
    padding: calc(var(--space-200) - 1px) var(--space-300);
    @include csp-hstack($align: center);
    justify-content: space-between;
    cursor: pointer;

    border: solid 1px var(--col-primary-neutral-080);
    border-radius: 8px;

    svg {
      transform: rotate(90deg);
    }
    // border-bottom: solid 1px var(--col-brand-neutral-80);

    > span {
      font-size: 14px;
    }
  }
}

.inputsWrapper {
  background-color: var(--col-primary-white);
  padding: var(--space-300);
  @include csp-common-shadow();
  border-radius: 8px;
  width: 200px;
}
