@import '../styles/_imports';

.root{
  @include csp-vstack();
  gap: csp-margin(m);
  padding: csp-padding(m);
  
  width: 100%;
  @include csp-breakpoint-up(lg){
    @include csp-hstack($justify: space-between);    
  }

  font-size: csp-font-size(s);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);   
  // padding: csp-padding(s); 
  border-radius: 0.5em;
  // background-color: red;

  // @include csp-hstack($align: center, $justify: space-around);
  // @include csp-padding($horizontal: s);
  // padding: 0;
  // width: 100%;
  // max-width: 580px;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: csp-margin(l);

  .sizes{
    @include csp-hstack();
    gap: csp-margin(xs);
    select{
      border-color: csp-color(fg, lighten);
      border-radius: 6px;
    }
  }
  
  .rightAligned{
    @include csp-hstack();
    gap: csp-margin(l);
    // .pages{
      // margin-left: auto;
      // margin-right: csp-margin(l);
    // }

    .paginationWrapper{
      @include csp-hstack();
      gap: csp-margin(xs);
  
  
      .pageBtn{
        padding: 0;
        border: none;
        background-color: transparent;
        font-weight: normal;
        @include csp-vstack($justify: space-around);
  
        color: csp-color(fg);
        svg path{
          fill: csp-color(fg);
        }
  
        &:disabled{
          color: csp-color(fg, lighten);
          svg path{
            fill: csp-color(fg, lighten);
          }
        }
        &.active{
          font-weight: 600;
        }
      }
    }    
  }



  // .navItem {


  //   > a{
  //     @include csp-hstack(center, center);

  //     > svg path{
  //       fill: csp-color(primary);
  //     }
  //     &:hover{
  //       background-color: csp-color(primary);
  //       color: csp-color(fg-invert);        
  //       > svg path{
  //         fill: csp-color(fg-invert);
  //       }
  //     }
  //   }

  //   &.active{
  //     > a{
  //       background-color: csp-color(primary);
  //       color: csp-color(fg-invert);                
  //     }
  //   }

  //   &.break a{
  //     border: none;
  //     cursor: default;
  //     &:hover{
  //       color: csp-color(primary);
  //       background-color: transparent;
  //     }
  //   }    

  //   &.disabled > a{
  //     border-color: csp-color(bg, darken);
  //     svg path{
  //       fill: csp-color(bg, darken);
  //     }    
  //   }    
  // }

  // .prev svg{
  //   transform: rotate((180deg));
  // }


}
