@import './imports';

// 4. Include any optional Bootstrap CSS as needed
// @import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/reboot';
// @import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
@import '../../node_modules/bootstrap/scss/containers';
// @import "../../node_modules/bootstrap/scss/grid";
@import '../../node_modules/bootstrap/scss/helpers';

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import '../../node_modules/bootstrap/scss/utilities/api';

// @import "../../node_modules/bootstrap/scss/buttons";

:root {
  --col-primary-white: #ffffff;
  --col-primary-black: #000000;

  // netral
  --col-brand-neutral-000: #ffffff;
  --col-brand-neutral-050: #eff4f5;
  --col-brand-neutral-100: #f7f9fa;
  --col-brand-neutral-125: #e2eaed;
  --col-brand-neutral-150: #d1dee3;
  --col-brand-neutral-200: #b7cbd3;
  --col-brand-neutral-250: #a3bdc7;
  --col-brand-neutral-300: #7098a7;
  --col-brand-neutral-400: #557c8b;
  --col-brand-neutral-700: #435d65;
  --col-brand-neutral-900: #374649;
  --col-brand-neutral-1000: #000000;

  --col-brand-secondary-150: #d1dee3;

  // Colors/Brand/Alt/Brand Secondary-150

  --col-secondary-teal: #01a592;
  --col-blue-blue-jeans: #53bbff;

  --col-todo-blue-light: #d6efff;
  --col-todo-green-light: #d9f2ca;
  --col-todo-yellow-light: #ffe996;

  --col-dataviz-blue-pale-cyan: #53bbff;
  --col-dataviz-blue-medium-persian: #006ea6;
  --col-red-light-salmon: #ff9c79;
  --col-functional-error-full: #ee0000;
  --col-functional-validation-full: #00884a;
  --col-functional-disabled-200: #b7cbd3;
  --col-green-kelly-green: #61c921;
  --col-dataviz-green-light-green: #a4e77b;
  --col-dataviz-brown-naples-yellow: #f4d35a;

  --col-dataviz-purple-middle-blue-purple: #8a63bc;

  --col-brown-gold-metallic: #dab531;

  --col-primary-neutral-100: #374649;
  --col-primary-neutral-090: #435d65;
  --col-primary-neutral-085: #557c8b;
  --col-primary-neutral-080: #7098a7;
  --col-primary-neutral-060: #a3bdc7;
  --col-primary-neutral-050: #b7cbd3;
  --col-primary-neutral-030: #d1dee3;
  --col-primary-neutral-020: #eff4f5;
  --col-primary-neutral-010: #f7f9fa;

  --col-brand-primary-500-light: #ebf4fd;

  //
  --col-primary-200: #f0f7fd;
  --col-primary-500: #0073e0;
  --col-functional-hover-primary-500-light: #ebf4fd;

  //
  --col-transparent: transparent;

  //
  --col-atenso-blue: #084567;

  // --col-base-000: #FFFFFF;
  // --col-base-200: #B7CBD3;
  // --col-base-400: #435D65;

  // --col-danger-400: #FF0000;
  // --col-warn-400: #DAB531;
  // --col-success-400: #61C921;
  // --col-info-400: #53BBFF;

  --space-0: 0rem;
  --space-100: 0.25rem;
  --space-200: 0.5rem;
  --space-300: 0.75rem;
  --space-400: 1rem;
  --space-500: 1.5rem;
  --space-600: 3rem;
  --space-700: 5rem;
  --space-auto: auto;

  --fs-default: 1rem;
  --fs-5xl: 2.75rem;
  --fs-4xl: 2.5rem;
  --fs-3xl: 2.25rem;
  --fs-2xl: 2rem;
  --fs-xl: 1.75rem;
  --fs-l: 1.25rem;
  --fs-m: 1rem;
  --fs-s: 0.9rem;
  --fs-xs: 0.75rem;
  --fs-2xs: 0.65rem;

  --lh-5xl: 1;
  --lh-4xl: 2.5rem;
  --lh-3xl: 2.25rem;
  --lh-2xl: 2rem;
  --lh-xl: 1.75rem;
  --lh-l: 1.75rem;
  --lh-m: 1.5rem;
  --lh-s: 1.25rem;
  --lh-xs: 1rem;
  --lh-2xs: 1rem;
  --lh-3xs: 1rem;
}

@keyframes bounce-down {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(20%);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(4%);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes bounce-right {
  0% {
    transform: scale(1, 1) translateX(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateX(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateX(20%);
  }
  50% {
    transform: scale(1.05, 0.95) translateX(0);
  }
  57% {
    transform: scale(1, 1) translateX(4%);
  }
  64% {
    transform: scale(1, 1) translateX(0);
  }
  100% {
    transform: scale(1, 1) translateX(0);
  }
}

@keyframes popup-appear {
  0% {
    transform: translateY(-50vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    transform: translateY(5vh);
  }
  100% {
    transform: translateY(0);
  }
}
.popup-content {
  animation: popup-appear 0.5s ease forwards;
  padding: 0 !important;
}

.csp-fit-text-v-center {
  @include csp-hstack($align: center);
}

.devPlaceholder {
  background-color: #87ab7b;
  opacity: 0.8;
  background-image: radial-gradient(#7b7eab 0.5px, #87ab7b 0.5px);
  background-size: 10px 10px;

  color: csp-color(fg, light);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: csp-font-size(l);
  text-transform: uppercase;
}

.DatePicker {
  z-index: auto;
  .DatePicker__calendarContainer {
    z-index: 100;
  }
  .DatePicker__calendarArrow {
    z-index: 100;
  }
}

html,
body,
:root {
  // background-color: #0352c8;
  line-height: 16.8px;
  font-size: 14px;

  --menu-width: 300px;

  // background: linear-gradient(180deg, #0096e9 0%, #0096e9 100%) 0% 0% / 100vw 100px content-box fixed,
  // linear-gradient(180deg, #0352c8 0%, #0352c8 100%) 0% 0% / 100vw 100px content-box fixed;

  background: repeating-linear-gradient(180deg, #0096e9 0%, #0352c8 100%);
  background-size: 100vw 100vh;
  background-clip: content-box;
  background-attachment: fixed;
  background-color: #0096e9;
}
