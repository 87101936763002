@import '../../styles/_imports';

.root {
  @include csp-vstack();
  gap: csp-margin(xxs);
  padding-bottom: csp-padding(xs);
  // min-width: 160px;

  .title {
    flex: 1;
    text-align: center;
    color: csp-color(fg, base);
    font-size: csp-font-size(s);
    font-weight: bold;
    // padding-bottom: csp-margin(xxs);
    border-bottom: csp-border-default();
    // wo
    max-width: 120px;
    // @include csp-ellipsis();
  }

  .counts {
    flex: 1;
    @include csp-hstack($align: center, $justify: center);
    gap: csp-margin(s);
    color: csp-color(fg, base);
    font-size: csp-font-size(s);

    .resources,
    .users {
      @include csp-hstack($align: center, $justify: center);
      gap: csp-margin(xxs);
      svg {
        @include csp-fix-size(16px);
      }
    }

    .resources {
      svg path {
        fill: csp-color(fg, base);
      }
    }

    .users {
      svg path {
        stroke: csp-color(fg, base);
      }
    }

    .noResources {
      font-size: csp-font-size(m);
      text-align: center;
    }
  }

  .progress {
    @include csp-hstack($align: center, $justify: center);

    svg {
      width: 16px;
      height: 16px;
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      animation: rotating 2s linear infinite;

      path {
        fill: csp-color(fg, light);
      }
    }
  }
}
