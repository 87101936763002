@import '../../styles/_imports';

.root {
  //

  .mainCard {
    height: 100%;
  }

  .tiles {
    padding: csp-padding(xxl);
    height: 100%;
    width: 100%;
    flex: 1;
    flex-basis: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: csp-margin(xxl);

    // >a {
    //   // width: 30;
    // }
  }
}
