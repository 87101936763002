@import '../styles/_imports';

.root {
  min-width: 160px;
  // width: 400px;

  .search {
    // background-color: red;
    :global(.tagSelectPref__control) {
      border-radius: 2em;
      background-color: csp-color(bg-invert, light);
      border: none;
    }

    :global(.tagSelectPref__menu) {
      z-index: 9999;
    }

    .control {
      min-height: auto;
      line-height: 20px;
    }

    .searchControlWrapper {
      @include csp-hstack($align: center, $justify: space-between);
      width: 100%;

      .searchControlIco {
        padding-right: csp-padding(s);
        @include csp-vstack($align: center);

        svg {
          @include csp-fix-size(12px);
        }

        // align-self: flex-end;
      }
    }
  }
}

.cardEntry {
  @include csp-hstack;
  // border-bottom: csp-border-default();

  > div {
    @include csp-vstack;
  }

  .body {
    text-align: left;
    width: 100%;
    // font-size: csp-font-size(s);

    b {
      font-weight: normal;
    }

    i {
      font-size: csp-font-size(s);
      font-weight: 300;
    }

    i:nth-of-type(1) {
      color: csp-color(orange);
    }

    i:nth-of-type(2) {
      color: csp-color(green);
    }
  }

  button svg path {
    stroke: csp-color(fg, lighten);
  }
}
