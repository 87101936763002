@import '../styles/_imports';

.icon {
  margin: var(--space-100);
  cursor: pointer;
  display: inline-flex;
  align-self: center;
  color: csp-color(fg);

  svg {
    width: 1em;
    height: 1em;
    top: 0.125em;
    position: relative;
  }
}
.content {
  width: 100%;
  // background-color: csp-color(bg);
  padding: var(--space-400);
  border-radius: 5px;
  border: csp-border-default();
  // border-color: csp-color(bg-invert, light);
  max-width: 300px;
  // color: csp-color(fg, light);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
