@import '../styles/_imports';

.root {
  @include csp-hstack($align: baseline);

  // @include csp-breakpoint-up(sm){
  //     @include csp-hstack($align: center);
  // }

  input {
    --inputSize: calc(#{csp-font-size(m)} + 2px);
    width: var(--inputSize);
    height: var(--inputSize);

    min-width: var(--inputSize);
    min-height: var(--inputSize);

    vertical-align: middle;

    accent-color: csp-color(primary);
    border: solid 1px csp-color(primary);
    border-radius: 2px;
    appearance: none;
    /* Safari support */
    -webkit-appearance: none;

    &:checked {
      appearance: auto;
    }
    &:disabled {
      accent-color: csp-color(fg, lighten);
      border: solid 1px csp-color(fg, lighten);
    }
  }

  .labelsContainer {
    @include csp-vstack();
    padding-left: 1em;

    &.disabled {
      color: csp-color(fg, light);
    }

    .radioLabel {
      //
    }
    .subLabel {
      margin-top: csp-margin(xxs);
      font-size: csp-font-size(xs);
      font-weight: 300;
    }
  }
}
