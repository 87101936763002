@import '../../styles/_imports';

.createTicketBtn {
  position: fixed;

  @include csp-hstack($align: center);
  gap: csp-margin(xxs);
  bottom: 20px;
  right: 1em;

  border-radius: 50%;
  min-height: 50px;
  min-width: 50px;

  > span {
    display: none;
  }

  &:hover {
    > span {
      display: block;
    }
    border-radius: 1em;
  }

  > .ico {
    @include csp-fix-size($size: 24px);
  }
  @include csp-breakpoint-up(xxl) {
    // center - half of a page - padding of page
    --half-full-page: #{csp-breakpoint(xxl) / 2};
    right: calc(50% - var(--half-full-page) + 1em);
  }
  // todo
}
