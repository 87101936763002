@import '../styles/_imports';

.content {
  @include csp-hstack($align: center);
  overflow: visible !important;

  .icon {
    display: flex;
    overflow: visible !important;
    svg {
      overflow: visible;
      height: csp-font-size(s);
      width: csp-font-size(s);
    }
  }
  .divider {
    display: flex;
    padding: 0 csp-padding(s);
    svg {
      height: csp-font-size(xs);
    }
  }
}
