@import '../../styles/_imports';

.clusterConfig {
  @include csp-hstack($align: center);
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
  .label {
    font-weight: bold;
    flex-grow: 1.4;
  }
  .issue {
    @include csp-hstack();
    .icon {
      margin-right: csp-margin(xs);
    }
    .meterData {
      svg path {
        fill: csp-color(fg, base);
      }
    }
    .content {
      font-weight: bold;
      .lmStatus {
        color: csp-color(green, base);
      }
      .clusterLimit {
        color: csp-color(gold, base);
      }
      .strategy {
        color: csp-color(fg, base);
      }

      .description {
        font-size: csp-font-size(xs);
        font-weight: normal;
      }
    }
  }
}

.diagram {
  .diagramTop {
    @include csp-hstack($align: center);
    .datePicker {
      max-width: 320px;
      align-self: flex-end;
      margin-left: auto;
    }
    .switchViewButton {
      margin-left: csp-margin(l);
      font-size: csp-font-size(xxs);
    }
  }
}
