@import '../styles/_imports';


.root{
  @include csp-vstack();
  max-height: 90vh;

  @include csp-breakpoint-up(sm){
    min-width: 360px;
  }
  @include csp-breakpoint-up(md){
    min-width: 420px;
  }  

  .header{
    @include csp-hstack($align: center, $justify: space-between);
    padding: csp-padding(xs);
    border-bottom: csp-border-default();
    box-shadow: #0000001a 0px 5px 2px -5px;

    > button{
      border: none;
      background-color: transparent;
    }
    > .title{
      text-transform: uppercase;
      flex: 1;
      text-align: center;
      color: csp-color(fg, dark);
      font-weight: bold;
    }
  }

  .body{
    @include csp-padding(m);
    min-height: 50px;
    @include csp-vstack();
    flex-grow: 1;
    flex-shrink: 0;
  }

  .footer{
    @include csp-vstack($align: stretch, $justify: flex-end);
    padding: csp-padding(m);
    gap: csp-padding(m);

    .actions {
      @include csp-hstack($align: center, $justify: space-between);
      padding-right: csp-padding(xxs);
      padding-bottom: csp-padding(xs);

      > button{
        flex: 1;
        margin-left: csp-margin(s);
        margin-right: csp-margin(s);
      }
      
    }

    
  }
}

:global{
  .delete-popup-content.popup-content {
    width: auto !important;
    max-height: 100%;
    overflow-y: auto;
  }
}