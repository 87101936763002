@import '../../../../styles/_imports';

.popup {
  width: 75vw;
  max-height: unset;
  max-width: unset;

  .nameAndClientContainer {
    @include csp-hstack($align: center);
    gap: csp-margin(m);
    > label:first-child {
      flex: 1;
    }

    .nameInput {
      min-width: 100px;
      max-width: 450px;

      @include csp-breakpoint-up(md) {
        min-width: 200px;
      }
    }

    .clientTitle {
      display: flex;
      min-width: 0;
      @include csp-breakpoint-up(md) {
        padding: csp-padding(l);
      }
      > div {
        padding: csp-padding(xxs) csp-padding(m);
        background-color: csp-color(primary, dark);
        border: 1px solid csp-color(primary);
        color: csp-color(bg, base);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 1em;
      }
    }
  }

  .typeOptions {
    padding-left: csp-padding(l);
  }

  .submitError {
    padding-left: 1px;
    color: csp-color(red);
    border-color: csp-color(red);
  }

  .actions {
    justify-content: right !important;
    button {
      max-width: 200px;
    }
  }
}
