@import '../../../styles/_imports';

.root {
  .filterCard {
    @include csp-hstack();
    gap: csp-margin(m);
    flex-wrap: wrap;

    .quickFilters {
      @include csp-hstack();
      gap: csp-margin(xs);
      align-items: flex-start;
    }
  }
}
