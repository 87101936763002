@import '../../styles/_imports';

.root {
  // border-radius: 8px;

  .displayHolder {
    @include csp-ellipsis();
    padding-left: var(--space-200);
  }

  :global(.singleSelectPrefix__control) {
    flex-wrap: nowrap !important;
    background-color: var(--col-brand-neutral-000) !important;
    border: solid 1px var(--col-primary-neutral-080) !important;
    border-radius: 8px !important;
    min-height: 32px !important;
    font-size: var(--fs-s);
  }
  :global(.singleSelectPrefix__control.singleSelectPrefix__control--menu-is-open) {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom: none !important;
    background-color: var(--col-brand-neutral-050) !important;
  }
  :global(.singleSelectPrefix__control.singleSelectPrefix__control--menu-is-open .singleSelectPrefix__dropdown-indicator) {
    transform: rotate(180deg);
  }

  :global(.singleSelectPrefix__value-container) {
    padding: var(--space-200) var(--space-300) !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  :global(.singleSelectPrefix__indicator-separator) {
    display: none;
  }
  :global(.singleSelectPrefix__input-container) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  :global(.singleSelectPrefix__dropdown-indicator) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    svg path {
      fill: var(--col-primary-500);
    }
  }
  :global(.singleSelectPrefix__placeholder) {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    font-style: normal;
    color: var(--col-primary-neutral-100);
    font-weight: 500;
    @include csp-ellipsis();
  }
  :global(.singleSelectPrefix__menu) {
    margin-top: 0px !important;
    border-radius: 8px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    box-shadow: none !important;
    border: solid 1px var(--col-primary-neutral-080);
    border-top: none;

    :global(.singleSelectPrefix__option) {
      border-bottom: solid 1px var(--col-primary-neutral-080);
      font-size: var(--fs-xs);
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
