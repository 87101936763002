@import '../../../../styles/_imports';

.popup {
  max-width: unset;
  max-height: unset;

  @include csp-breakpoint-up(lg) {
    width: unset;
  }

  .popupBody {
    > label,
    > div {
      gap: 0.5em !important;
      @include csp-breakpoint-up(md) {
        gap: 2em !important;
      }

      > div:first-child {
        min-width: 150px !important;
      }
    }
  }

  .rolesRow {
    overflow: hidden;
    .rolesWrapper {
      @include csp-vstack($align: flex-start);
      max-width: 350px;

      .roles {
        @include csp-vstack($align: flex-start);
        flex: 1;
        background-color: csp-color(bg-invert, light);
        border-radius: 20px;
        padding: csp-padding(m);
        margin: 5px 10px 10px 2px;
        gap: csp-margin(xs);
        overflow-y: scroll;

        &.standard {
          width: 99%;
          max-height: 200px;
        }

        &.individual {
          width: 99%;
          max-height: 80px;
        }

        &.hasValidationError {
          border: 1px solid csp-color(red);

          > label > div > input {
            border-color: csp-color(red);
          }
        }

        .warning {
          input {
            color: csp-color(warn);
            border-color: csp-color(warn);
          }
        }
      }

      .rolesMessages {
        margin-top: csp-margin(xs);
        padding-left: csp-padding(m);
        //min-height: 20px;

        .warning {
          color: csp-color(warn);
          border-color: csp-color(warn);
        }
        .error {
          color: csp-color(red);
          border-color: csp-color(red);
        }
      }
    }
  }

  .IndividualHint {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;

    color: csp-color(primary);
    cursor: pointer;

    div {
      display: table;
      max-width: 350px;
      span {
        padding-left: 0.5em;
        display: table-cell;
      }
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }

  .infoIco {
    padding-left: csp-padding(xs);
    width: min-content;
    cursor: pointer;
    svg {
      @include csp-fix-size(16px);
    }
    svg path {
      fill: csp-color(brand-primary-500, base);
    }
  }
}
