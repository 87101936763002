@import '../../styles/_imports';

.panelBtn {
  @include csp-vstack($align: center);
  svg {
    @include csp-fix-size(24px);
  }
}
.contextActionsBtn {
  svg {
    rotate: 90deg;
  }
}

.totalEnergyConsumption {
  background-color: var(--col-brand-neutral-050);
  padding: var(--space-300);
  text-align: center;
  font-weight: 500;
}
