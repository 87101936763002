@import '../styles/_imports';

.root {
  @include csp-hstack($align: center);
  gap: csp-margin(s);
  @include csp-padding($all: s);
  max-width: 470px;
  border-radius: 1em;
  &.mute {
    background-color: csp-color(off, lighten);
  }
  .icoContainer {
    // @include csp-fix-size(38px);
    border: solid 4px csp-color(bg);
    border-radius: 50%;
    // padding: 4px;
    @include csp-hstack($align: center);
    // background-color: lime;
    // overflow: hidden;

    svg {
      // @include csp-fix-size(28px);
      width: 28px;
    }
  }
}
