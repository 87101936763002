@import '../../../../styles/_imports';

.root {
  .nameCol {
    @include csp-hstack($align: center);
    width: 100%;
    gap: csp-margin(s);
    > div {
      @include csp-fix-size(36px);
    }
    > span {
      flex: 1;
    }
  }

  .appliedFilterArea {
    @include csp-hstack();
    gap: csp-margin(xxs);
    .filteredRole {
      background-color: csp-color(off, light);
      color: csp-color(fg-inactive);
      max-width: 116px;
      svg path {
        stroke: csp-color(fg-inactive);
      }
      // @include csp-ellipsis();
    }
  }

  .actions {
    @include csp-hstack();
    gap: csp-margin(s);
    margin-left: auto;
    .editBtn {
      @include csp-breakpoint-up(md) {
        min-width: 100px;
      }
      @include csp-breakpoint-up(lg) {
        min-width: 140px;
      }
    }
    .icoBtn {
      @include csp-btn();
      min-width: auto !important;
    }
  }

  .filters {
    @include csp-hstack();
    gap: csp-margin(s);
    flex-wrap: wrap;
  }
}
