@import '../styles/_imports';

.icon {
  // margin: csp-margin(xxs);
  // margin-left: var(--space-200);
  cursor: pointer;
  transform: translateY(-33%);
  // display: inline-flex;
  // align-self: center;
  // color: csp-color(fg);

  svg {
    @include csp-fix-size(12px);
    path {
      fill: var(--col-primary-500);
    }
    // height: 100%;
    // width: 100%;
    // width: 1em;
    // height: 1em;
    // top: .125em;
    // position: relative;
  }
}
.content {
  width: 100%;
  background-color: csp-color(bg);
  padding: csp-padding(m);
  border-radius: 5px;
  border: csp-border-default();
  border-color: csp-color(bg-invert, light);
  max-width: 300px;
  color: csp-color(fg, light);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
