@import '../styles/_imports';

.root {
  :global(.react-switch-handle) {
    box-shadow: 0px 6px 8px 0px #b7cbd352, 0px 1px 1px 0px #7098a7a3 !important;
  }

  &.check {
    :global(.react-switch-handle) {
      background-color: var(--col-primary-500) !important;
    }
  }

  :global(.react-switch-bg) {
    background-color: var(--col-brand-neutral-200) !important;
    border: solid 2px var(--col-brand-neutral-900) !important;
  }
}
