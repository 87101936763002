@import '../../../styles/_imports';

.root {
  //
  .projectGrid {
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: csp-margin(m);

    .gridItem {
      border: solid 1px csp-color(primary);
      border-radius: 6px;
      padding: csp-padding(s);
      @include csp-vstack();
      gap: csp-margin(s);
      h4 {
        color: csp-color(fg, light);
        font-size: csp-font-size(m);
        margin: 0;
      }
      .branchesRow {
        @include csp-vstack();
        gap: csp-margin(xs);
      }
      .statusRow {
        font-size: csp-font-size(xs);
        @include csp-hstack();
        gap: csp-margin(s);

        .branch {
          @include csp-vstack($align: center, $justify: center);
          width: 80px;
          font-size: csp-font-size(m);
          font-weight: bold;
          text-transform: uppercase;
          color: csp-color(fg);
          background-color: csp-color(bg, dark);
        }
        .stats {
          @include csp-vstack();

          b.success {
            color: csp-color(success);
          }
          b.manual,
          b.created,
          b.waiting_for_resource,
          b.skipped,
          .schedule {
            color: csp-color(warn);
          }
          b.failed,
          b.canceled {
            color: csp-color(danger);
          }
          b.preparing,
          b.pending,
          b.running {
            color: csp-color(deepBlue);
          }
        }

        &.branchStatusSuccess {
          .branch {
            background-color: csp-color(success);
          }
        }
        &.branchStatusWarn {
          .branch {
            background-color: csp-color(warn);
          }
        }
        &.branchStatusAlert {
          .branch {
            background-color: csp-color(danger, light);
          }
        }
      }
    }
  }
}
