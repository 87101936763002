@import '../styles/_imports';
.dateRangeCalendar {
  // background-color: lime;
  // @include csp-fix-size(64px);

  select option {
    text-align: center;
    background-color: white;
  }

  :global(.rdrMonth) {
    width: 312px;
  }

  :global(.rdrWeekDays) {
    // background-color: lime;
    :global(.rdrWeekDay) {
      font-size: 16px;
      font-weight: 700;
      color: var(--col-primary-neutral-100);
    }
  }

  :global(.rdrDays) {
    gap: 2px;
    padding: 2px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    > button {
      width: 40px;
      height: 32px;
    }
  }
  :global(.rdrDay) {
    border: solid 1px var(--col-primary-neutral-030);
    font-size: 16px;
    line-height: 20px;
    height: 32px;
    border-radius: 8px;
    @include csp-common-shadow();
    box-shadow: 0px 6px 8px 0px #b7cbd352, 0px 1px 1px 0px #7098a7a3;
    margin-top: 8px;
    // width: 40px;
    :global(.rdrDayNumber) {
      font-weight: 500;

      > span {
        color: var(--col-primary-neutral-100);
      }
    }
    // background-color: lime;
  }
  :global {
    .rdrMonthPicker,
    .rdrYearPicker {
      > select {
        font-weight: 700;
        font-size: var(--fs-m);
        padding: var(--space-200);
        padding-right: 30px;
        text-align: right;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893Z' fill='%230073E0'/%3E%3C/svg%3E%0A");
        background-position-x: calc(100% - 10px);
        &:hover {
          background-color: var(--col-functional-hover-primary-500-light);
        }
      }
    }

    .rdrNextPrevButton {
      background-color: transparent;
      > i {
        border-color: transparent;
        border: 2px solid var(--col-primary-500);
        border-left: none;
        border-top: none;
        width: 10px;
        height: 10px;
      }

      &.rdrPprevButton > i {
        transform: rotate(-225deg);
      }
      &.rdrNextButton > i {
        transform: rotate(-45deg);
      }
    }

    .rdrDay .rdrInRange,
    .rdrDay .rdrStartEdge,
    .rdrEndEdge {
      background-color: transparent;
    }
    .rdrDay {
      .rdrDayStartPreview,
      .rdrDayEndPreview,
      .rdrDayInPreview {
        border: none;
      }
    }
    .rdrDay.rdrDayToday .rdrDayNumber > span::after {
      bottom: -2px;
      width: 4px;
      height: 3px;
      border-radius: 3px;
    }
    .rdrDay.rdrDayDisabled {
      background-color: var(--col-brand-neutral-200);
    }
    .rdrDay:has(.rdrInRange),
    .rdrDay:has(.rdrDayInPreview) {
      background-color: #ebf4fd;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top-style: dashed;
      border-bottom-style: dashed;
      box-shadow: none;
      .rdrDayNumber > span {
        color: var(--col-primary-neutral-080);
      }
    }
    .rdrDay:has(.rdrStartEdge),
    .rdrDay:has(.rdrDayStartPreview) {
      background-color: var(--col-primary-500);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .rdrDayNumber > span {
        color: var(--col-primary-white);
      }
    }
    .rdrDay:has(.rdrEndEdge),
    .rdrDay:has(.rdrDayEndPreview) {
      background-color: var(--col-primary-500);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .rdrDayNumber > span {
        color: var(--col-primary-white);
      }
    }
    .rdrDay:has(.rdrStartEdge).rdrDay:has(.rdrEndEdge),
    .rdrDay:has(.rdrDayStartPreview).rdrDay:has(.rdrDayEndPreview) {
      border-radius: 8px;
    }

    .rdrDay.rdrDayPassive {
      box-shadow: none;
      border: none;
    }

    // .rdrDay:has(.rdrDayStartPreview), .rdrDay:has(.rdrDayEndPreview){
    //   background-color: #EBF4FD;
    //   .rdrDayNumber > span{
    //     color: var(--col-primary-neutral-080);
    //   }
    // }
  }

  // :global(.rdrDay.rdrDayPassive) {
  //   box-shadow: none;
  //   border: none;
  //   :global(.rdrDayNumber) {
  //     > span {
  //       color: var(--col-primary-neutral-085);
  //     }
  //   }
  // }

  :global {
    .rdrMonthsHorizontal .rdrMonth:nth-child(2) {
      border-left: 1px solid var(--col-brand-neutral-200);
    }
  }
}

.dateRangeDisplay {
  // @include csp-hstack($align:center);
  // gap:var(--space-500);
  width: fit-content;
  .inputsWrapper {
    border: solid 1px var(--col-brand-neutral-200);
    border-right: none;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    padding: var(--space-100) var(--space-200) var(--space-100) var(--space-500);
  }

  .fromWrapper,
  .toWrapper {
    @include csp-hstack($align: center);
    gap: var(--space-200);
    color: var(--col-brand-neutral-900);
    font-size: 14px;
    font-weight: 400;
    input {
      width: 96px;
      background-color: var(--col-primary-white);
      color: var(--col-brand-neutral-900);
      border-color: var(--col-brand-neutral-300);
      cursor: pointer;
    }
  }
  .btn {
    background-color: var(--col-primary-500);
    border-radius: 0;
    @include csp-vstack($align: center, $justify: center);
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    height: 100%;
    padding: var(--space-300);
    svg {
      @include csp-fix-size(16px);
      path {
        fill: var(--col-primary-white);
      }
    }
  }
}

.popoverContainer {
  padding: var(--space-500) var(--space-200);
  background-color: var(--col-primary-white);
  border: solid 1px var(--col-brand-neutral-900);
  border-radius: 8px;
  @include csp-common-shadow();

  .popoverFooter {
    button {
      width: 240px;
    }
  }
}
