@import '../../styles/_imports';

.root {
  @include csp-vstack();
}

.header.sticky {
  position: sticky;
  top: 0;
}

.row,
.headerRow,
.sumRow {
  @include csp-hstack();
  // background-color: lightcoral;
}

.sumRow {
  background-color: csp-color(bg-invert, dark);
  color: csp-color(fg-invert);
  

  border-bottom: csp-border-default();
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  margin-top: #{csp-padding(s)*-1};
  padding-top: csp-padding(s);
  margin-bottom: csp-padding(s);
}

// .row:nth-child(even){
//   background-color: lightblue;
// }
.headerRow {
  // @include csp-padding($vertical: s);

  >.hrColWrapper {
    background-color: csp-color(bg);
    // @include csp-padding($vertical: m);
    font-size: csp-font-size(s);
    font-weight: bold;
    overflow-x: hidden;
    // height: 100%;

    // border-left: csp-border-default();
    // border-right: csp-border-default();

    // border-right: csp-border-default();
    &:last-child {
      border-right: csp-border-default();
    }

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    // border-bottom: csp-border-default();
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;

  }

  // margin-bottom: csp-margin(xs);
  // background-color: csp-color(bg, darken);
  // border-radius: 0.25em;
}

.headerRow,
.sumRow,
.row {
  gap: 4px;
  &.hidden{
    display: none;
  }
}

.row {
  margin-bottom: csp-margin(xxs);
  background-color: csp-color(bg-invert, light);
  // @include csp-padding($vertical: s);
  border-radius: 0.5em;
  overflow-x: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.hrColWrapper,
.trColWrapper,
.sumColWrapper {
  @include csp-hstack();

  padding: 12px 8px 12px 8px;
  // @include csp-padding($vertical: s);

  &.colRightAlign{
    justify-content: right;
  }

  &.vcenter {
    align-items: center;
  }

  >* {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  flex: 1;

  &.noSumCol {
    display: none;
  }

  &.sortableCol {
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      min-width: 18px;
      width: 18px;
      path {
        stroke: csp-color(fg);
      }

      &.inactiveSort {
        path {
          stroke: csp-color(fg, lighten);
        }
      }
    }
  }
}