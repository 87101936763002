@import '../../styles/_imports';

.exportOptions {
  //width: 50vw;
  max-height: unset;
  max-width: unset;

  .progress {
    @include csp-vstack($align: center);

    svg {
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      animation: rotating 4s linear infinite;
    }
  }

  .columnGroupsList {
    @include csp-vstack($align: center);
    gap: csp-margin(m);
    padding: csp-padding(s) 2px;
    margin: 0px csp-padding(s);

    .accordionHeadline {
      @include csp-hstack($align: center, $justify: space-between);
    }

    .columns {
      @include csp-vstack();
      gap: csp-margin(m);
      margin-left: csp-margin(m);
      padding-left: csp-padding(l);
    }

    .checkBoxValidationError {
      > label > div > input {
        border-color: csp-color(red);
      }
    }
  }

  .groupsValidation {
    padding-left: csp-margin(m);
    color: csp-color(red);
    border-color: csp-color(red);
  }

  .popupRow {
    border-bottom: csp-border-default();
    .popupRowLbl {
      width: 250px !important;
    }
  }

  .fileTypeOptions {
    @include csp-hstack();
    gap: csp-margin(m);
  }

  .aggregationOptions {
    @include csp-vstack();
  }

  .footer {
    border-top: csp-border-default();
    @include csp-hstack($align: center, $justify: space-between);
    .info {
      @include csp-hstack();
      flex: 1;
      padding-left: csp-margin(m);
      gap: csp-margin(xxs);
      font-size: csp-font-size(s);
      font-weight: 300;
      svg {
        fill: csp-color(fg, light);
      }
    }
    .actions {
      @include csp-hstack($align: center, $justify: space-between);
      padding-right: csp-padding(xxs);
      padding-bottom: csp-padding(xs);
      padding-top: csp-padding(xs);

      > button {
        flex: 1;
        margin-left: csp-margin(s);
        margin-right: csp-margin(s);
        min-width: 150px;
        max-width: 200px;
      }
    }
  }
}
