@import '../../../../styles/_imports';

.deletePopupRow {
  max-width: 420px;
  &.alert {
    color: csp-color(red);
  }
  &.warn {
    color: csp-color(yellow);
  }
}

.submitError {
  padding-left: 2px;
  color: csp-color(red);
  border-color: csp-color(red);
}
