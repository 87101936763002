@import '../styles/_imports';

.toggleBtn {
  @include csp-hstack($align: center);
  gap: csp-margin(xs);
  border-radius: 1em;
  min-width: 60px;
  border: none;
  background-color: csp-color(bg);
  // background-color: csp-color(bg, dark);
  outline: solid 1px csp-color(border);
  box-shadow: 0px 0px 6px 0px rgba(csp-color(border), 0.6);
  padding-right: csp-padding(s);
  .ico {
    @include csp-fix-size(13px);
    @include csp-vstack($align: center, $justify: center);
    > * {
      min-width: 100%;
      min-height: 100%;
    }
  }

  &.checked {
    background-color: csp-color(bg, dark);
    box-shadow: none;
    outline: none;
    .stateIco {
      outline: 2px solid csp-color(bg);
      // border: 1px solid csp-color(bg);
      // border-radius: 50%;
      // .stateIco{}
    }
  }

  // .label{
  //   //
  // }
}
.stateIco {
  background-color: lime;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  // @include csp-fix-size(13px);

  &.apperance_available,
  &.apperance_success {
    background-color: #01a592;
  }
  &.apperance_charging {
    background-color: #53bbff;
  }
  &.apperance_occupied {
    background-color: #dab531;
  }
  &.apperance_defective,
  &.apperance_alert {
    background-color: #ee0000;
  }
  &.apperance_notavailable,
  &.apperance_undef {
    background-color: #435d65;
  }
  &.apperance_unknown {
    background-color: #b7cbd3;
  }
}
