@import './variables';

@mixin csp-vstack($align: unset, $justify: unset) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $justify;
}

@mixin csp-hstack($align: unset, $justify: unset) {
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: $justify;
}

@mixin csp-shadow-md {
  // todo ...
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px
}

@mixin csp-overflow-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin csp-reset-list {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

@mixin csp-page {
  padding: csp-padding(xs);

  @include csp-breakpoint-up(lg) {
    padding: csp-padding(m);
  }
}

@mixin csp-fix-size($size) {
  max-height: $size;
  max-width: $size;
  min-height: $size;
  min-width: $size;
}

@mixin app-main-bg() {
  background: linear-gradient(180deg, #0096e9 0%, #0352c8 100%);
  background-size: 100vw 100vh;
  background-clip: content-box;
  background-attachment: fixed;
}

@mixin csp-cirlce($size, $borderColor: null, $borderWidth: null) {
  @include csp-fix-size($size);

  border-radius: 50%;
  @if ($borderColor or $borderWidth) {
    border-style: solid;
    @if ($borderColor) {
      border-color: csp-color($borderColor);
    }
    @if ($borderWidth) {
      border-width: $borderWidth;
    }
  }
}

@mixin csp-muted-placeholder {
  font-style: italic;
  color: csp-color(fg, light);
  font-weight: 200;
}

@mixin csp-anim-rotate {
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: rotation 1s infinite linear;
}

@mixin csp-button($bg: transparent, $fg: null) {
  // todo ...
  border-style: none;
  border-radius: 0.25em;
  @if ($bg) {
    background-color: $bg;
  }
  @if ($fg) {
    color: $fg;
  }

  -webkit-appearance: none;
  -moz-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline-style: solid;
    outline-color: ur-color(primary);
    outline-width: 2px;
  }
}

@mixin csp-title($size: m, $style: mute) {
  @if ($size == l) {
    font-size: csp-font-size(l);
  }
  @if ($size == m) {
    font-size: csp-font-size(s);
  }
  @if ($size == s) {
    font-size: csp-font-size(xs);
  }

  @if ($style == mute) {
    color: csp-color(fg, light);
    text-transform: uppercase;
  }
  @if ($style == headline) {
    color: csp-color(fg);
  }
}

@mixin csp-margin($all: null, $top: null, $right: null, $bottom: null, $left: null) {
  @if ($all) {
    margin-top: csp-padding($all);
    margin-right: csp-padding($all);
    margin-bottom: csp-padding($all);
    margin-left: csp-padding($all);
  }

  @if ($top) {
    margin-top: csp-margin($top);
  }
  @if ($right) {
    margin-right: csp-margin($right);
  }
  @if ($bottom) {
    margin-bottom: csp-margin($bottom);
  }
  @if ($left) {
    margin-left: csp-margin($left);
  }
}

@mixin csp-padding($all: null, $top: null, $right: null, $bottom: null, $left: null, $horizontal: null, $vertical: null) {
  @if ($all) {
    padding-top: csp-padding($all);
    padding-right: csp-padding($all);
    padding-bottom: csp-padding($all);
    padding-left: csp-padding($all);
  }
  @if ($top) {
    padding-top: csp-padding($top);
  }
  @if ($right) {
    padding-right: csp-padding($right);
  }
  @if ($bottom) {
    padding-bottom: csp-padding($bottom);
  }
  @if ($left) {
    padding-left: csp-padding($left);
  }
  @if ($horizontal) {
    padding-left: csp-padding($horizontal);
    padding-right: csp-padding($horizontal);
  }
  @if ($vertical) {
    padding-top: csp-padding($vertical);
    padding-bottom: csp-padding($vertical);
  }
}

@mixin csp-btn {
  background-color: transparent;
  color: csp-color(primary);
  @include csp-padding($top: xs, $bottom: xs, $left: xs, $right: xs);
  min-width: 80px;
  border: none;

  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

@mixin csp-make-page-inner-container() {
  @include csp-breakpoint-till(md) {
    &.phone-auto {
      width: 100%;
      min-width: 100%;
      flex-basis: 100%;
    }
  }

  @include _csp-page-inner-container-sizes('phone', csp-margin(xxs) / 2);

  @include csp-breakpoint-up(md) {
    @include _csp-page-inner-container-sizes('tablet', csp-margin(xs) / 2);
  }

  @include csp-breakpoint-up(lg) {
    @include _csp-page-inner-container-sizes('desktop', csp-margin(s) / 2);
  }
}

@mixin _csp-page-inner-container-sizes($prefix, $margin) {
  // &.#{$prefix}-auto{
  //   // width: auto;
  // }
  &.#{$prefix}-full {
    // flex-basis: 100%;
    width: 100%;
    min-width: 100%;
  }
  &.#{$prefix}-three-fourth {
    // flex-basis: 75%;
    width: calc(75% - #{$margin});
    max-width: calc(75% - #{$margin});
    flex-basis: calc(75% - #{$margin});
    // flex: 3 3 0;
  }
  &.#{$prefix}-two-third {
    // flex-basis: 66.66666%;
    width: calc(66.6666% - #{$margin});
    max-width: calc(66.6666% - #{$margin});
    flex-basis: calc(66.6666% - #{$margin});
    // flex: 2 2 0;
  }
  &.#{$prefix}-half {
    width: calc(50% - #{$margin});
    max-width: calc(50% - #{$margin});
    flex-basis: calc(50% - #{$margin});

    // flex: 1 1 0;
  }
  &.#{$prefix}-two-fourth {
    width: calc(50% - #{$margin});
    max-width: calc(50% - #{$margin});
    flex-basis: calc(50% - #{$margin});
    // flex: 2 2 0;
  }
  &.#{$prefix}-one-third {
    // flex-basis: 33.333333%;
    width: calc(33.3333% - #{$margin});
    max-width: calc(33.3333% - #{$margin});
    flex-basis: calc(33.3333% - #{$margin});
    // flex: 1 1 0;
  }
  &.#{$prefix}-one-fourth {
    // flex-basis: 25%;
    width: calc(25% - #{$margin});
    max-width: calc(25% - #{$margin});
    flex-basis: calc(25% - #{$margin});
    // flex: 1 1 0;
  }
}

@mixin csp-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin csp-form-card-fixed-header($headlineSelectors) {
  overflow-y: auto;

  // the context menu
  > button:first-child {
    position: sticky !important;
    top: 0;
    margin-left: auto;
    margin-top: -38px;
    z-index: 1;
  }

  // the headline
  #{$headlineSelectors} {
    position: sticky;
    top: 0;
    background-color: csp-color(bg);
    margin-bottom: 10px;
  }
}

@mixin csp-comp-nothing-here {
  @include csp-vstack($align: center, $justify: center);
  text-align: center;
  font-size: csp-font-size(m);
  font-style: italic;
  color: csp-color(fg, light);
  font-weight: 300;
  height: 100%;
}

@mixin csp-comp-filter-box {
  background-color: csp-color(bg);
  @include csp-padding(s);
  @include csp-hstack;
  gap: csp-margin(l);
  flex-wrap: wrap;

  > * {
    @include csp-hstack(center);
    color: csp-color(fg, light);
    font-weight: 600;

    > span {
      margin-left: csp-margin(xs);
      font-size: csp-font-size(s);
      flex: 1;
    }
  }
}

@mixin csp-common-shadow() {
  box-shadow: 0px 8px 8px 0px color-mix(in srgb, var(--col-brand-neutral-200) 32%, transparent), 0px 1px 1px 0px color-mix(in srgb, var(--col-brand-neutral-200) 80%, transparent);
}

@mixin csp-control-shadow() {
  box-shadow: 0px 6px 8px 0px #b7cbd352, 0px 1px 1px 0px #b7cbd3cc;
}

@mixin gen-responsive-box($map) {
  @each $itemK, $itemV in $map {
    $clsName: $itemK;
    &.#{$clsName} {
      @include print-style($itemV);
    }
  }
  @each $k, $v in $_csp-breakpoints {
    @each $itemK, $itemV in $map {
      @media (min-width: map-get($map: $_csp-breakpoints, $key: $k)) {
        $clsName: $k + '-resp-' + $itemK;
        &.#{$clsName} {
          @include print-style($itemV);
        }
      }
    }
  }
}
@mixin print-style($map) {
  @each $k, $v in $map {
    #{$k}: #{$v};
  }
}
