@import '../styles/_imports';

.root {
  @include csp-hstack($align: center);
  justify-content: flex-end;
  padding-bottom: csp-padding(xs);

  .filterItem {
    @include csp-padding($horizontal: xs, $vertical: xxs);
    font-size: csp-font-size(xs);
    color: csp-color(fg);
    cursor: pointer;

    border: csp-border-default();
    border-color: csp-color(primary);
    border-radius: 4px;

    > svg {
      margin-left: csp-margin(s);
      @include csp-fix-size(8px);
      transform: rotate(90deg);
      transition: transform 0.8s ease;
      path {
        fill: csp-color(fg, light);
      }
    }

    &:hover {
      background-color: csp-color(primary);
      color: csp-color(fg-invert);
      border-style: transparent;
      > svg path {
        fill: csp-color(fg-invert);
      }
    }

    &.expanded {
      > svg {
        transform: rotate(-90deg);
      }
    }
  }
}

.popoverContent {
  border: csp-color(primary) solid 1px;
  padding: csp-padding(s);
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: csp-color(bg);
  .timeFilterButtonGroup {
    display: grid;
    grid-template-columns: 1fr;
    gap: csp-margin(xs);
    .timeFilterButton {
      border-radius: 5px;
      font-size: csp-font-size(s);
      text-align: left;
      height: 35px;
      cursor: pointer;
      background-color: csp-color(bg, base);
      border: 1px solid csp-color(primary, base);
      color: csp-color(primary, base);
      margin: csp-margin(xxxs);
      padding: csp-padding(xxs);
      &:hover,
      .selectedTimeFilter {
        background-color: csp-color(primary, base);
        color: csp-color(bg, base);
      }
    }
    .selectedTimeFilter {
      background-color: csp-color(primary, base);
      color: csp-color(bg, base);
    }
  }
}
.popoverArrow {
  border-bottom-color: csp-color(primary) !important;
}
