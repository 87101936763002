@import '../../../../styles/_imports';

.popup {
  max-height: unset;

  .body {
    min-height: unset;
    justify-content: center;
    min-width: 450px;
  }

  .deleteNode {
    @include csp-hstack();
    gap: csp-margin(s);
    color: csp-color(red);
    cursor: pointer;
    padding: csp-padding(s) csp-padding(s) 0 csp-padding(s);
    svg path {
      fill: csp-color(red);
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }
}
