@import '../../styles/_imports';

.editCustomName {
  border: solid 1px var(--col-brand-neutral-300) !important;
  padding: var(--space-100) var(--space-200);
  @include csp-common-shadow();
}

.charts {
  background-color: var(--col-primary-white);
  padding: var(--space-500);
  border: solid 1px var(--col-brand-neutral-900);
  border-radius: 8px;
}

.tableContainer {
  background-color: var(--col-primary-white);
  // padding: var(--space-500);
  border: solid 1px var(--col-brand-neutral-900);
  border-radius: 8px;
  // overflow: hidden
  padding-bottom: var(--space-600);
}

.badgeStatusWrapper {
  overflow: hidden;
  padding: 1px;
  .stateActive,
  .stateInactive {
    width: 8px;
    border: solid 1px var(--col-primary-500);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .stateActive {
    background-color: var(--col-primary-500);
  }
}

.toggleBtn {
  @include csp-btn();
  @include csp-hstack($align: center, $justify: space-between);
  color: var(--col-primary-neutral-100);
  font-weight: 500;
  border: solid 1px var(--col-primary-neutral-080);
  background-color: var(--col-primary-white);

  @include csp-common-shadow();
  border-radius: 32px;

  &:hover {
    text-decoration: none;
  }
}
