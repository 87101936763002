@import '../../styles/_imports';

.page {
  background-color: var(--bs-white);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  padding-top: var(--space-500);
  padding-bottom: var(--space-700);
  padding-left: var(--space-200);
  padding-right: var(--space-200);
  border-top-left-radius: 40px;
  background-color: #f0f5f6;
  height: 100%;
  // flex: 1;
}
.contentInner {
  max-width: csp-breakpoint($size: xxl);
  // min-width: csp-breakpoint($size: lg);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: var(--space-600);
}

.header {
  padding: var(--space-300);
  padding-left: var(--space-600);
  padding-top: var(--space-100);
  padding-bottom: var(--space-500);

  transition: all 0.3s;
  // background-color: var(--bs-white);
}

:global(.menu-expanded) {
  .header {
    padding-left: calc(var(--space-600) + var(--menu-width) - var(--nav-collapsed-width));
  }
}

.mainLogo {
  @include csp-fix-size(48px);

  img {
    width: 100%;
    height: 100%;
  }
}

.apiError {
  .code {
    font-size: csp-font-size(xs);
    color: csp-color(primary);
  }
  .txt {
    font-size: csp-font-size(xl);
    margin-top: csp-margin(l);
  }
}
.successPanel,
.errorPanel,
.spinner {
  height: 100%;
  width: 100%;
  @include csp-vstack($align: center, $justify: center);
  gap: csp-margin(s);
  flex: 1;

  svg {
    @include csp-fix-size(68px);
    path {
      fill: csp-color(accent);
    }
  }
  .panelContent {
    @include csp-vstack($align: center);
    text-align: center;
    max-width: csp-breakpoint(sm);
    > button {
      margin-top: csp-margin(m);
    }
  }
}

.spinner {
  .defaultLoadingMsg {
    color: csp-color(fg, light);
    font-weight: bold;
    // text-transform: uppercase;
    letter-spacing: 8px;
  }
  // animation: rotation 1s infinite linear;
  svg {
    @include csp-anim-rotate();
  }
}

.successPanel {
  svg path {
    fill: csp-color(success);
  }
}
