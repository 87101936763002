@import '../styles/_imports';

@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.skeletonShimmer {
  $base-col1: csp-color(bg, darken);
  $base-col2: csp-color(bg, dark);

  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #{$base-col1} 10%, #{$base-col2} 18%, #{$base-col1} 33%);
  background-size: 800px 104px;
  height: 100px;
  position: relative;
}

.root {
  // @include csp-page;
  // @include csp-vstack;
  background-color: #f0f5f6;
}
.rootInner {
  @include csp-page;
  @include csp-hstack;
  min-height: 100vh;
  // width: 100%;
  flex: 1;
  // position: relative;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  background-color: #f0f5f6;
  max-width: csp-breakpoint($size: xxl);
  margin-left: auto;
  margin-right: auto;
  // column-gap: csp-padding(xxs);
  row-gap: csp-padding(l);

  &.fetching {
    // align-content: unset;
    align-content: flex-start;
  }

  .topbar {
    @include csp-hstack($justify: space-between);
    border-bottom: csp-border-default();
    padding-bottom: csp-padding(s);
    box-shadow: #0000001a 0px 5px 2px -5px;
    width: 100%;
  }

  // @include csp-breakpoint-up(md){
  //   column-gap: csp-padding(s);
  // }
  // @include csp-breakpoint-up(lg){
  //   column-gap: csp-padding(l);
  //   // @include csp-margin($right: m);
  // }

  .simplePlaceholder,
  .errorPage {
    @include csp-vstack($align: center, $justify: center);
    height: 80vh;
    width: 100%;
    flex: 1;
    flex-basis: 100%;

    .loaderTxt {
      font-size: csp-font-size(l);
      text-transform: uppercase;
      color: csp-color(primary, light);

      color: csp-color(accent);
      background: csp-gradient();
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .code {
      font-size: csp-font-size(xs);
      color: csp-color(primary);
    }
    .txt {
      font-size: csp-font-size(xl);
      margin-top: csp-margin(l);
    }
    .ico {
      @include csp-fix-size(120px);
      border-radius: 50%;
      background-color: csp-color(primary, light);
      padding: csp-padding(l);
      margin-bottom: csp-margin(s);
      animation: err-ico-pulse 5s infinite;

      @keyframes err-ico-pulse {
        0% {
          background-color: csp-color(primary, light);
        }
        50% {
          background-color: csp-color(primary);
        }
        100% {
          background-color: csp-color(primary, light);
        }
      }

      svg {
        @include csp-fix-size(100%);
        path {
          fill: csp-color(fg-invert);
        }
      }
    }
  }
  .spinnerGrid {
    width: 100%;

    .card {
      border-color: csp-color(bg, darken);

      &.fullWidthCard {
        width: 100%;
      }

      > h3,
      > div > div {
        height: 20px;
        margin-bottom: csp-margin(xxs);
      }

      > h3 {
        width: 40%;
      }
      > div > div:nth-child(1) {
        width: 60%;
      }
      > div > div:nth-child(2) {
        width: 30%;
      }
      > div > div:nth-child(3) {
        width: 90%;
      }

      &:hover {
        background-color: csp-color(bg);
      }
    }
  }

  .rippleSpinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    @keyframes lds-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
        // border-color: #743ad5;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
        // border-color: #d53a9d;
      }
    }

    div {
      position: absolute;
      border: 4px solid;
      border-color: csp-color(primary);
      // border-image-source: csp-gradient();
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    div:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}
