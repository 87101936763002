@import '../styles/_imports';

.root {
  @include csp-hstack($align: center);

  background-color: csp-color(bg-invert, light);
  border-radius: 2em;
  max-width: 380px;
  @include csp-padding($horizontal: s);

  > label > input {
    &::placeholder {
      font-family: var(--bs-body-font-family) !important;
      font-size: var(--bs-body-font-size) !important;
      font-weight: var(--bs-body-font-weight) !important;
      font-style: normal !important;
      color: csp-color(fg, light) !important;
    }
  }

  .inputControl {
    flex: 1;
    input {
      background-color: transparent;
      border: none;
    }
  }
  .icoWrapper {
    @include csp-hstack($align: center);
    > svg {
      @include csp-fix-size(19px);
      path {
        fill: csp-color(fg);
      }
    }
  }
}
