@import '../../styles/_imports';

.root{
  height: 100%;
  width: 100%;
  padding: 20px;
  border-radius: 50%;

  transition: all .6s ease-in;
  transform: rotate(0deg);

  &.counterClockWise{
    transform: scale(-1, 1);
  }

  &.animate{
    padding: 0%;
    box-shadow:  none;

    .holeOuter .hole{
      padding: 0%;
      box-shadow:  none;
    }
  }  


  .holeOuter{
    padding: 5px;
    width: 100%;
    height: 100%;    
    background-color: csp-color(bg);
    border-radius: 50%;

    .hole{
      width: 100%;
      height: 100%;
      border-radius: 50%;

      transition: all .6s ease-in;

      @include csp-vstack($align: center, $justify: center);
    }

    b{
      padding-left: csp-padding(xs);
      padding-right: csp-padding(xs);
      text-align: center;
    }
  }

}