@import '../../../../styles/_imports';

.diagram {
  flex: 1;
  min-height: 60vh;
  width: 100%;
  height: 100%;
}
.contextMenu {
  // Context menu should be shown inside/on top of popup. Popups default z-index is 999.
  z-index: 1000;
}
