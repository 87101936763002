@import '../styles/_imports';

.pageLayout {
  font-size: 14px;
  line-height: 16px;
  // position: relative;
  width: 100%;
  background-color: csp-color(bg, dark);
  @include csp-vstack();
  flex: 1;

  .pageLayoutInner {
    @include csp-vstack();
    flex: 1;
    width: 100%;
    max-width: csp-breakpoint($size: xxl);

    padding: csp-padding(xs);
    gap: csp-margin(s);
    margin-left: auto;
    margin-right: auto;

    @include csp-breakpoint-up(lg) {
      padding: csp-padding(l);
    }
  }
}

.pageTitle {
  font-size: csp-font-size(l);
  font-weight: 600;
  color: csp-color(fg, light);
}

.pageDesc {
  color: csp-color(fg);
  font-weight: 400;

  &.descAlert {
    color: csp-color(danger);
    font-weight: bold;
  }
}

.pageBody {
  border: solid 1px csp-color(fg);
  background-color: csp-color(bg);
  border-radius: 8px;
  @include csp-padding($horizontal: m, $vertical: m);
  @include csp-vstack();
  margin-top: csp-margin(s);
  gap: csp-margin(l);
}
.pageFooter {
  @include csp-hstack($justify: flex-end);
  gap: csp-margin(m);
  margin-top: csp-margin(s);
  button {
    font-size: csp-font-size(s);
    // @include csp-padding($vertical: s);
  }
  // justify-items: right;
  // button{
  //   width: fit-content;
  // }
}

.formRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
  gap: csp-margin(s);
  &.noWrap {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
  .spacer {
    display: none;
  }
  @include csp-breakpoint-up(md) {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    .spacer {
      display: block;
    }
  }
}

.formRowCol1 {
  grid-column-end: span 1;
}
.formRowCol2 {
  grid-column-end: span 2;
}
.formRowCol3 {
  grid-column-end: span 3;
}
.formRowCol4 {
  grid-column-end: span 4;
}
.formRowCol5 {
  grid-column-end: span 5;
}
.formRowCol6 {
  grid-column-end: span 6;
}
.formRowCol7 {
  grid-column-end: span 7;
}
.formRowCol8 {
  grid-column-end: span 8;
}

.separator {
  margin: 0;
  // margin-bottom: #{csp-margin(s) * -1};
  // margin-top: #{csp-margin(s) * -1};
}
.formGroupTitle {
  font-size: csp-font-size(s);
  font-weight: 600;
  margin-bottom: 0;
}

.apiError {
  .code {
    font-size: csp-font-size(xs);
    color: csp-color(primary);
  }
  .txt {
    font-size: csp-font-size(xl);
    margin-top: csp-margin(l);
  }
}
.successPanel,
.errorPanel,
.spinner {
  height: 100%;
  width: 100%;
  @include csp-vstack($align: center, $justify: center);
  gap: csp-margin(s);
  flex: 1;

  svg {
    @include csp-fix-size(68px);
    path {
      fill: csp-color(accent);
    }
  }
  .panelContent {
    @include csp-vstack($align: center);
    text-align: center;
    max-width: csp-breakpoint(sm);
    > button {
      margin-top: csp-margin(m);
    }
  }
}

.spinner {
  .defaultLoadingMsg {
    color: csp-color(fg, light);
    font-weight: bold;
    // text-transform: uppercase;
    letter-spacing: 8px;
  }
  // animation: rotation 1s infinite linear;
  svg {
    @include csp-anim-rotate();
  }
}

.successPanel {
  svg path {
    fill: csp-color(success);
  }
}
