@import '../../../styles/_imports';

.stationListItem {
  border-radius: 8px;
  overflow: hidden;
  // min-height: 102px;
  border: solid 1px var(--col-primary-neutral-050);
  background-color: var(--col-brand-neutral-000);
  padding: 1px;
  @include csp-common-shadow();

  .contentArea {
    padding: var(--space-200);
    line-height: 1rem;
  }

  .lmStatus {
    padding: 0 var(--space-200);
    font-size: var(--fs-xs);
    color: var(--col-primary-500);
    border-radius: 8px;
    border: solid 1px var(--col-brand-neutral-200);
  }

  .editCustomName {
    border: solid 1px var(--col-brand-neutral-300);
    padding: var(--space-100) var(--space-200);
    @include csp-common-shadow();
  }

  .statusNoch {
    min-width: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .stationGroup {
    border-bottom: 1px solid var(--col-brand-neutral-200);
    .stationGroupItem {
      border: solid 1px var(--col-primary-neutral-050);
      border-radius: 8px;
      margin: 5px;
    }
  }
}

.cpContainer {
  padding-left: var(--space-500);
}

.statisticPill {
  padding: 1px;
  // overflow: hidden;
  min-height: 28px;
  min-width: 52px;
  border: solid 1px var(--col-brand-neutral-200);
  border-radius: 8px;
  font-weight: 500;
  .notch {
    min-width: 8px;
    min-height: 100%;
    align-self: stretch;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
