@import '../../../styles/_imports';

.root {
  border-radius: 8px;
  overflow: hidden;
  min-height: 102px;
  border: solid 1px var(--col-primary-neutral-050);
  background-color: var(--col-brand-neutral-000);
  padding: 1px;
  @include csp-common-shadow();

  .statusNoch {
    min-width: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .domainStatusLbl {
    padding: var(--space-0) var(--space-200);
    border-radius: 4px;
    font-size: var(--fs-xs);
    font-weight: 700;
    width: fit-content;
    border-width: 2px !important;
  }

  .lmStatus {
    padding: 0 var(--space-200);
    font-size: var(--fs-xs);
    color: var(--col-primary-500);
    border-radius: 8px;
    border: solid 1px var(--col-brand-neutral-200);
  }

  .editCustomName {
    border: solid 1px var(--col-brand-neutral-300);
    padding: var(--space-100) var(--space-200);
    @include csp-common-shadow();
  }

  .contentArea {
    padding: var(--space-200);
    line-height: 1rem;
  }

  .energyTag {
    padding: var(--space-100) var(--space-400);
  }
}
