@import '../../styles/imports';

.multiStepBody {
  font-size: 18px;
  line-height: 1.4rem;
}

.stepIco {
  width: 120px;
  height: 120px;
  border: 1px solid var(--col-brand-neutral-200);
  border-radius: 50%;
  background: linear-gradient(360deg, var(--col-brand-neutral-100) 0%, var(--col-brand-neutral-200) 100%);
  padding: 16px;
  > svg {
    width: 80px;
    height: 80px;
    border-radius: 50%;

    box-shadow: 0px 6px 8px 0px #37464952, 0px 1px 1px 0px #374649cc;
    border: 1px solid var(--col-brand-neutral-250);
    background-color: white;
  }
}
.failedIco,
.successIco,
.partialIco,
.spinnerIco {
  border-radius: 50%;
}

.spinnerIco {
  svg {
    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    animation: rotating 4s linear infinite;
  }
}
