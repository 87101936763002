@import '../styles/_imports';

.compStyle {
  &.roleKind {
    &.root {
      background-color: csp-color(deepBrown);
      color: csp-color(fg-invert);
      &.light {
        background-color: csp-color(deepBrown, light);
        color: csp-color(deepBrown);
      }
    }
    &.client {
      background-color: csp-color(deepPurple);
      color: csp-color(fg-invert);
      &.light {
        background-color: csp-color(deepPurple, light);
        color: csp-color(deepPurple);
      }
    }
  }
  &.rateType {
    &.all {
      background-color: csp-color(primary, dark);
      color: csp-color(fg-invert);
      &.invert {
        color: csp-color(primary, dark);
        background-color: csp-color(fg-invert);
      }
    }
    &.employee {
      background-color: csp-color(deepGreen);
      color: csp-color(deepGreen, light);
      &.invert {
        color: csp-color(deepGreen);
        background-color: csp-color(deepGreen, light);
      }
    }
    &.home {
      background-color: csp-color(deepPurple);
      color: csp-color(deepPurple, light);
      &.invert {
        color: csp-color(deepPurple);
        background-color: csp-color(deepPurple, light);
      }
    }
    &.work {
      background-color: csp-color(lemonGreen);
      color: csp-color(lemonGreen, light);
      &.invert {
        color: csp-color(lemonGreen);
        background-color: csp-color(lemonGreen, light);
      }
    }
    &.unknown {
      background-color: csp-color(bg-invert, dark);
      color: csp-color(fg-invert);
      &.invert {
        color: csp-color(off);
        background-color: csp-color(bg-invert);
      }
    }
    &.public {
      background-color: csp-color(deepBrown);
      color: csp-color(deepBrown, light);
      &.invert {
        color: csp-color(deepBrown);
        background-color: csp-color(deepBrown, light);
      }
    }
  }

  &.activeInactive {
    &.active {
      background-color: csp-color(green);
      &.invert {
        background-color: csp-color(green, light);
      }
    }
    &.inactive {
      background-color: csp-color(accent);
      &.invert {
        background-color: csp-color(accent, light);
      }
    }
  }

  &.stationStatus {
    &.define {
      --total: #{csp-color(primary, dark)};
      --online: #{csp-color(green, base)};
      --offline: #{csp-color(yellow, base)};
      --failure: #{csp-color(red, base)};
      --notInOperation: #{csp-color(fg, light)};
      --transparent: #{csp-color(transparent)};
    }
    &.total {
      background-color: csp-color(primary, dark);
    }
    &.online {
      background-color: csp-color(green, base);
      &.active {
        background-color: #{csp-color(green, base)};
        color: #{csp-color(green, light)};
      }
      &.inactive {
        background-color: #{csp-color(green, light)};
        color: #{csp-color(green, base)};
      }
    }
    &.offline {
      background-color: csp-color(yellow, base);
      &.active {
        background-color: #{csp-color(yellow, base)};
        color: #{csp-color(yellow, light)};
      }
      &.inactive {
        background-color: #{csp-color(yellow, light)};
        color: #{csp-color(yellow, base)};
      }
    }
    &.failure {
      background-color: csp-color(red, base);
      &.active {
        background-color: #{csp-color(red, base)};
        color: #{csp-color(red, light)};
      }
      &.inactive {
        background-color: #{csp-color(red, light)};
        color: #{csp-color(red, base)};
      }
    }
    &.notInOperation {
      background-color: csp-color(fg, light);
      &.active {
        background-color: #{csp-color(fg, light)};
        color: #{csp-color(bg-inactive)};
      }
      &.inactive {
        background-color: #{csp-color(bg-inactive)};
        color: #{csp-color(fg, light)};
      }
    }
    &.transparent {
      background-color: csp-color(transparent);
    }
  }

  &.connectorsStatus {
    &.define {
      --total: #{csp-color(primary, dark)};
      --available: #{csp-color(blueGreen)};
      --charging: #{csp-color(deepBlue)};
      --unavailable: #{csp-color(fg, base)};
      --unknown: #{csp-color(bg-invert, dark)};
      --failure: #{csp-color(accent)};
      --occupied: #{csp-color(orange, dark)};
    }
    &.total {
      background-color: csp-color(primary, dark);
    }
    &.available {
      background-color: csp-color(blueGreen);
      &.active {
        background-color: csp-color(blueGreen, base);
        color: csp-color(blueGreen, light);
      }
      &.inactive {
        background-color: csp-color(blueGreen, light);
        color: csp-color(blueGreen, base);
      }
      &.textColor {
        color: csp-color(blueGreen);
        background-color: unset;
      }
    }
    &.charging {
      background-color: csp-color(deepBlue);
      &.active {
        background-color: csp-color(deepBlue, base);
        color: csp-color(deepBlue, light);
      }
      &.inactive {
        background-color: csp-color(deepBlue, light);
        color: csp-color(deepBlue, base);
      }
      &.textColor {
        color: csp-color(deepBlue, base);
        background-color: unset;
      }
    }
    &.unavailable {
      background-color: csp-color(fg, base);
      &.active {
        background-color: csp-color(fg, base);
        color: csp-color(bg-invert, base);
      }
      &.inactive {
        background-color: csp-color(bg-invert, base);
        color: csp-color(fg, base);
      }
      &.textColor {
        color: csp-color(fg, base);
        background-color: unset;
      }
    }
    &.occupied {
      background-color: csp-color(orange, dark);
      &.active {
        background-color: csp-color(orange, dark);
        color: csp-color(orange, light);
      }
      &.inactive {
        background-color: csp-color(orange, light);
        color: csp-color(orange, dark);
      }
      &.textColor {
        color: csp-color(orange, dark);
        background-color: unset;
      }
    }
    &.unknown {
      background-color: csp-color(bg-invert, dark);
      &.active {
        background-color: csp-color(bg-invert, dark);
        color: csp-color(bg-invert, base);
      }
      &.inactive {
        background-color: csp-color(bg-invert, base);
        color: csp-color(bg-invert, dark);
      }
      &.textColor {
        color: csp-color(bg-invert, dark);
        background-color: unset;
      }
    }
    &.failure {
      background-color: csp-color(accent);
      &.active {
        background-color: csp-color(secondary, base);
        color: csp-color(secondary, light);
      }
      &.inactive {
        background-color: csp-color(secondary, light);
        color: csp-color(secondary, base);
      }
      &.textColor {
        color: csp-color(secondary, base);
        background-color: unset;
      }
    }
  }

  &.sessionStatus {
    &.charging {
      background-color: csp-color(deepBlue);
      color: csp-color(deepBlue, light);
      &.invert {
        background-color: csp-color(deepBlue, light);
        color: csp-color(deepBlue);
      }
    }
    &.occupied {
      background-color: csp-color(orange);
      color: csp-color(orange, light);
      &.invert {
        background-color: csp-color(orange, light);
        color: csp-color(orange);
      }
    }
    &.closed {
      background-color: csp-color(green);
      color: csp-color(green, light);
      &.invert {
        background-color: csp-color(green, light);
        color: csp-color(green);
      }
    }
  }
  &.workloadStatus {
    &.high {
      background-color: csp-color(red, pale);
      color: csp-color(red, dark);
      &.invert {
        background-color: csp-color(red, dark);
        color: csp-color(red, pale);
      }
    }
    &.medium {
      background-color: csp-color(orange, light);
      color: csp-color(gold, dark);
      &.invert {
        background-color: csp-color(gold, dark);
        color: csp-color(orange, light);
      }
    }
    &.low {
      background-color: csp-color(green, pale);
      color: csp-color(green, dark);
      &.invert {
        background-color: csp-color(green, dark);
        color: csp-color(green, pale);
      }
    }
  }
  &.infrastructureLevelPill {
    &.active {
      background-color: csp-color(primary, base);
      color: csp-color(fg-invert);
      border-color: csp-color(primary, base);
      svg path {
        fill: csp-color(fg-invert);
      }
    }
    &.inactive {
      background-color: csp-color(fg-invert);
      color: csp-color(primary, base);
      border-color: csp-color(primary, base);
      svg path {
        fill: csp-color(primary, base);
      }
    }
  }
}
