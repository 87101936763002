@import '../../styles/_imports';

.root {
  // background-color: csp-color(bg);
  gap: csp-margin(m);

  .gridCardBody {
    background-color: transparent !important;
    border: none !important;
  }

  .selectedTags {
    margin-top: -#{csp-margin(m)};
    @include csp-hstack();
    gap: csp-margin(xs);

    .appliedTag {
      background-color: csp-color(bg-inactive, base);
      color: csp-color(fg-inactive, base);
      svg path {
        stroke: csp-color(fg-inactive);
      }
    }
  }

  .filters {
    @include csp-hstack();
    gap: csp-margin(xs);
    align-items: flex-start;

    &.rateTypeFilters {
      @include csp-breakpoint-up(md) {
        justify-content: flex-end;
        margin-left: auto;
      }
    }
  }

  .filterArea {
    padding-top: csp-margin(l);
    padding-bottom: csp-margin(s);

    @include csp-breakpoint-till(xl) {
      @include csp-vstack();
    }

    @include csp-hstack();
    gap: csp-margin(s);
    align-items: flex-start;

    > div {
      flex: 1;

      @include csp-breakpoint-till(xl) {
        max-width: 350px;
        min-width: 350px;
      }
    }

    .workloadFilters {
      @include csp-hstack();
      gap: csp-margin(xs);
      justify-content: flex-end;
      margin-left: auto;

      @include csp-breakpoint-till(xl) {
        justify-content: flex-start;
        margin-left: unset;
      }
    }
  }

  .pill {
    font-weight: 600;
  }

  .btnDetails {
    font-size: csp-font-size(xs);
    font-weight: 600;
    min-width: 120px !important;
  }

  .vcenter {
    align-items: center;
  }

  .searchInput {
    max-width: 350px;
    min-width: 350px;
    margin-bottom: csp-padding(s);

    @include csp-breakpoint-up(xl) {
      max-width: 247px;
      min-width: 0px;
    }
  }

  .strategySelect,
  .statusSelect {
    max-width: 350px;
    min-width: 350px;
    margin-bottom: csp-padding(s);

    @include csp-breakpoint-up(xl) {
      max-width: 120px;
      min-width: 0px;
    }
  }

  .tooltip {
    max-width: 250px;
    font-size: csp-font-size(xs);
    font-weight: 600;
    padding: csp-padding(xxs);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .wrapLabel {
    white-space: normal;
  }
}
