@import '../styles/_imports';

.root {
  @include csp-button();

  height: 12px;

  padding: var(--space-100);
  border-radius: 2rem;
  // font-size: csp-font-size(xs);
  min-width: 36px;
  width: 36px;
  // flex-shrink: 0;

  white-space: nowrap;

  &.hasChildren {
    width: fit-content;
    height: fit-content;
  }

  cursor: inherit !important;
  &.clickable:not(:disabled) {
    cursor: pointer;
  }
}
