@import '../../styles/_imports';

.root {
  .titleValue {
    @include csp-hstack($justify: space-between);
    gap: csp-padding(s);
    height: 50px;
  }

  .toolbar {
    @include csp-hstack($justify: left);
    gap: csp-margin(m);
  }

  .statusCol {
    border-left: 16px solid transparent;

    &.activeRow {
      border-left-color: csp-color(success);
    }

    &.inactiveRow {
      border-left-color: csp-color(danger);
    }

    .activeCol {
      color: csp-color(success);
    }

    .inactiveCol {
      color: csp-color(danger);
    }
  }
}
