@import '../../styles/_imports';

.filterArea {
  border-top: 1px solid csp-color(bg-invert, base);
  padding-top: csp-padding(l);
  padding-bottom: csp-padding(s);

  @include csp-breakpoint-till(lg) {
    @include csp-vstack();
  }

  @include csp-hstack();
  gap: csp-margin(s);
  align-items: flex-start;

  > div {
    @include csp-hstack();
    flex-wrap: wrap;
    @include csp-breakpoint-up(lg-minus-one-pixel) {
      width: 50%;
    }
    gap: csp-margin(xs);
    > div {
      flex: 1;
      max-width: 250px;
      min-width: fit-content;
    }
  }

  .filterContainer1 {
    .searchInput {
      min-width: 200px;
    }
  }

  .filterContainer2 {
    justify-content: flex-end;
    margin-left: auto;

    @include csp-breakpoint-till(lg) {
      justify-content: flex-start;
      margin-left: unset;
    }
  }
}
