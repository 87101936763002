@import '../../styles/_imports';

.sortIco {
  svg {
    transform: scale(2.2);
    opacity: 0.32;
  }

  &.active {
    svg {
      opacity: 1;
    }
  }
}

.root {
  background-color: var(--col-brand-neutral-100);

  .allHeaders {
    position: sticky;
    top: 0;
    margin-bottom: var(--space-200);
  }

  .cellInner {
    padding-right: var(--space-200);
    height: 100%;
  }

  .headerWrapper {
    background-color: var(--col-brand-neutral-100);

    padding: 0 var(--space-200);
    .header {
      border-bottom: solid 1px var(--col-brand-neutral-200);
      .sorting {
        cursor: pointer;
      }
      .cellInner {
        border-right: solid 1px var(--col-brand-neutral-200);
        @include csp-hstack();
        gap: var(--space-400);
      }
      > div:last-child .cellInner {
        border-right: none;
      }
    }
  }

  .subHeaderWrapper {
    padding: 0 var(--space-200);
    margin-bottom: var(--space-200);
    .subHeader {
      background-color: var(--col-primary-neutral-020);
      border-bottom: solid 1px var(--col-brand-neutral-200);
    }
  }

  .data {
    padding: 0 var(--space-200);
    .rowWrapper {
      background-color: var(--col-primary-white);
      border-radius: 8px;
      border: solid 1px var(--col-brand-neutral-200);
      @include csp-common-shadow();
    }
  }
}
