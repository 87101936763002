// $_csp-colors2: (
//   base: (
//     // 100: #B7CBD3,
//     // 100: #B7CBD3,
//     200: #B7CBD3
//   )
// );

$_csp-colors: (
  light: (
    brand-primary-200: (
      base: #b7cbd3,
    ),
    brand-primary-300: (
      base: #7098a7,
    ),
    brand-primary-500: (
      base: #0073e0,
      light: #0075e30f,
    ),
    neutral-85: (
      base: #557c8b,
    ),
    primary: (
      // lighten: #d4e3f9,
      light: #c2dafe,
      base: #0d6efd,
      dark: #012b6a,
      // darken: #001e4b,
    ),
    // primary-invert: (
    //   base: $mm-color-white,
    //   light: lighten($mm-color-white, $mm-color-shade-lighten),
    //   lighter: lighten($mm-color-white, $mm-color-shade-lighten * 2),
    //   dark: darken($mm-color-white, $mm-color-shade-darken),
    //   darker: darken($mm-color-white, $mm-color-shade-darken * 2),
    // ),
    secondary:
      (
        base: #ff0000,
        light: #ffbfbf,
      ),
    accent: (
      lighten: #d1e4ff,
      light: #ffbfbf,
      base: #ff0000,
      dark: #a30000,
      // darken: #012B6A,
    ),
    lemonGreen: (
      base: #d6d961,
      light: #f4f5d7,
    ),
    deepPurple: (
      base: #7646b2,
      light: #dcd0eb,
    ),
    deepBlue: (
      base: #00abfb,
      light: #bfeafe,
    ),
    deepBrown: (
      base: #9b8284,
      light: #e6dfe0,
    ),
    deepGreen: (
      base: #468d8d,
      light: #d0e2e2,
    ),
    blueGreen: (
      base: #09c8a5,
      light: #c1f1e8,
    ),
    orange: (
      light: #ffd98e,
      base: #ffa80b,
      dark: #d08600,
    ),
    gold: (
      dark: #825e1d,
      base: #ddaa4d,
    ),
    red: (
      base: #ff0000,
      light: #ffbfbf,
      dark: #901111,
      pale: #e3c3c3,
    ),
    green: (
      base: #54d14d,
      light: #d4f3d2,
      dark: #34674b,
      pale: #ccd9d2,
    ),
    yellow: (
      base: #fec100,
      light: #feefbf,
    ),
    transparent: (
      base: #d9d9d9,
    ),
    // OLD STYLES
    success:
      (
        base: #85c33d,
        light: #caead4,
        lighten: #e9f6ed,
      ),
    danger: (
      base: #ed0000,
      light: #f9a3a3,
    ),
    warn: (
      base: #e6a935,
      light: #ffefca,
    ),
    info: (
      base: #0d6efd,
    ),
    off: (
      base: #697577,
      light: #dbdfe6,
      lighten: #edf2f7,
    ),
    // deepPurple:(
    //   base: #9123a5,
    // ),
    purple:
      (
        base: #5b33ae,
      ),
    fg: (
      dark: #212a2c,
      base: #374649,
      light: #697577,
      lighten: #ced3d4,
    ),
    border: (
      base: #7098a7,
    ),
    bg: (
      base: #ffffff,
      dark: #edf2f7,
      darken: #dbdfe6,
    ),
    fg-invert: (
      base: #ffffff,
      dark: #f8fafc,
      darken: #dbdfe6,
    ),
    bg-invert: (
      dark: #9d9d9d,
      base: #e7e7e7,
      light: #f3f3f3,
      lighten: #f9f9f9,
    ),
    bg-inactive: (
      base: #dbdfe6,
    ),
    fg-inactive: (
      base: #938fa3,
    ),
    hl: (
      base: hsl(0, 0%, 80%),
    ),
    hl-hover: (
      base: hsl(0, 0%, 60%),
    ),
  ),
);

$_csp-spaces: (
  none: 0em,
  auto: auto,
  xxs: 0.25em,
  xs: 0.5em,
  s: 0.75em,
  m: 1em,
  l: 1.5em,
  xl: 3em,
  xxl: 5em,
);

$_csp-gradients: (
  common: linear-gradient(to left, #743ad5, #d53a9d),
);

$_csp-font-sizes: (
  default: 1em,
  xxxl: 2.5rem,
  xxl: 2rem,
  xl: 1.75rem,
  l: 1.25rem,
  m: 1em,
  s: 0.9em,
  xs: 0.75em,
  xxs: 0.65em,
);

// better breakpoints (from bootstrap) preferred instead of bulma
$_csp-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  // lg-minus-one-pixel was needed to prevent csp-breakpoint-up(lg)
  // and csp-breakpoint-till(lg) being active at the same time (this might
  // cause the layout to break) when the window width is exactly 992px.
  // Another possibility would be always reducing the max-width by one pixel
  // in csp-breakpoint-till.
  lg-minus-one-pixel: 991px,
  lg: 992px,
  xl-minus-one-pixel: 1199px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin csp-breakpoint-up($size: sm) {
  @media (min-width: map-get($map: $_csp-breakpoints, $key: $size)) {
    @content;
  }
}
@mixin csp-breakpoint-till($size: sm) {
  @media (max-width: map-get($map: $_csp-breakpoints, $key: $size)) {
    @content;
  }
}

@mixin csp-is-page {
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  flex: 1;

  @include csp-margin($left: auto, $right: auto);

  @include csp-breakpoint-up(sm) {
    max-width: 540px;
  }
  @include csp-breakpoint-up(md) {
    max-width: 720px;
  }
  @include csp-breakpoint-up(lg) {
    max-width: 960px;
  }
  @include csp-breakpoint-up(xl) {
    max-width: 1140px;
  }
  @include csp-breakpoint-up(xxl) {
    max-width: 1320px;
  }
}

@function csp-gradient($style: common) {
  $result: map-get(
    $map: $_csp-gradients,
    $key: $style,
  );
  @return $result;
}

@function csp-breakpoint($size: sm) {
  $result: map-get(
    $map: $_csp-breakpoints,
    $key: $size,
  );
  @return $result;
}

@function csp-color($base, $shade: base, $palette: light) {
  $paletteColors: map-get($_csp-colors, $palette);
  $color: map-get(map-get($paletteColors, $base), $shade);
  @return $color;
}

@function csp-border-default() {
  @return 1px solid csp-color(fg, lighten);
}

@function csp-margin($size: xxs) {
  $result: map-get(
    $map: $_csp-spaces,
    $key: $size,
  );
  @return $result;
}

@function csp-padding($size: m) {
  $result: map-get(
    $map: $_csp-spaces,
    $key: $size,
  );
  @return $result;
}

@function csp-font-size($size: default) {
  $result: map-get(
    $map: $_csp-font-sizes,
    $key: $size,
  );
  @return $result;
}
