@import '../../styles/_imports';

.root {
  @include csp-vstack();
  gap: csp-margin(l);

  @include csp-breakpoint-up(lg) {
    @include csp-hstack();
  }

  .chart {
    @include csp-breakpoint-up(sm) {
      @include csp-hstack();
      gap: csp-margin(xl);
    }
    @include csp-vstack();
    flex: 1;
    gap: csp-margin(m);

    .donut {
      .chartTitle {
        color: csp-color(fg);
        font-weight: bold;
        text-align: center;

        font-size: csp-font-size(xxs);

        @include csp-breakpoint-up(lg) {
          font-size: csp-font-size(xs);
        }
      }

      > div {
        aspect-ratio: 1;
        height: 200px;
        width: 200px;
      }
    }

    .legend {
      @include csp-hstack();
      flex-wrap: wrap;

      @include csp-breakpoint-up(sm) {
        @include csp-vstack();
      }

      max-width: fit-content;
      font-size: csp-font-size(s);
    }
  }

  .divider {
    background-color: csp-color(bg-invert, dark);

    min-height: 1px;
    min-width: 1px;

    @include csp-breakpoint-up(lg) {
      min-height: 100%;
      width: 1px;
    }
  }
}
