@import '../../styles/_imports';

.root {
  // .node{
  //   background-color: lime;
  //   padding: csp-padding(s);
  //   border-radius: 1em;
  // }
}

.nodesUnselectable {
  :global {
    .react-flow__node {
      cursor: default;
    }
  }
}
.nodesSelecteable {
  :global {
    .react-flow__node {
      cursor: pointer;
    }
  }
}

.node {
  // background-color: lime;
  @include csp-padding($vertical: s, $horizontal: l);
  border-radius: 1.5em;
  border: 4px solid transparent;

  .body {
    $width: 200px;
    max-width: $width;
    width: $width;
    text-align: center;
    @include csp-ellipsis();
  }

  // overflow: hidden;
  // white-space:

  .handle {
    @include csp-fix-size(12px);
    margin-left: -4px;
    margin-right: -4px;
    // background-color: csp-color(bg-invert, dark);
  }

  .actions {
    @include csp-hstack();
    //gap: csp-margin(xxs);
    position: absolute;
    z-index: 1000;
    right: 4px;
    bottom: -20px;
    span {
      cursor: pointer;
    }
  }

  :global {
    .react-flow__handle-connecting {
      background-color: csp-color(warn);
    }
    .react-flow__handle-valid {
      background-color: csp-color(success);
    }
  }

  &.clientRoot {
    background-color: csp-color(bg-invert, dark);
    color: csp-color(fg-invert);
    .handle {
      background-color: csp-color(primary, dark);
    }
  }
  &.region {
    background-color: csp-color(orange, light);
    color: csp-color(fg);
    .handle {
      background-color: csp-color(orange, light);
    }
  }
  &.location {
    background-color: csp-color(orange, base);
    color: csp-color(fg);
    .handle {
      background-color: csp-color(orange, base);
    }
  }
  &.area {
    background-color: csp-color(orange, dark);
    color: csp-color(fg-invert);
    .handle {
      background-color: csp-color(orange, dark);
    }
  }
  &.disabled {
    cursor: default;
    background-color: csp-color(bg-invert, base);
    color: csp-color(fg, dark);
    .handle {
      background-color: csp-color(bg-invert, base);
    }
  }

  &.autoCreated {
    background-color: csp-color(fg, base);
    color: csp-color(fg-invert);
    .handle {
      background-color: csp-color(fg, base);
    }
  }

  &.selected {
    border-style: solid;
    border-color: csp-color(primary);
  }
  &.selectedPartial {
    border-style: dashed;
    border-color: csp-color(primary);
  }
  &.selectedInherited {
    border-style: dashed;
    border-color: csp-color(fg, light);
  }
}

.node.rootSelectNode {
  .body {
    $width: 200px;
    max-width: $width;
    width: $width;
    @include csp-hstack();
    flex-wrap: wrap;

    .title {
      flex: 1;
      display: inline-flex;
      align-items: center;
    }

    .action {
      @include csp-hstack();
      //gap: csp-margin(xs);
      margin-left: auto;
      align-self: flex-end;

      svg path {
        //stroke: csp-color(fg);
        fill: csp-color(fg-invert);
      }
    }
  }
}

.edge {
}

.controls {
  position: absolute !important;
  bottom: 0 !important;
  left: auto !important;
  right: 0 !important;
}

.tooltip {
  opacity: 1 !important;
  padding: csp-padding(xxs) csp-padding(xs) !important;
}
