@import '../../styles/_imports';

// .popupMain {
//   background: transparent;
//   border: none;
// }

.highlightBox {
  max-width: 470px;
}
.popup {
  max-width: 520px;
  .body {
    @include csp-padding($vertical: l);
    @include csp-vstack($align: center);
    gap: csp-margin(l);
    padding-top: csp-padding(m);
  }
  .infoIco {
    svg path {
      fill: csp-color(primary);
    }
  }

  // FULL RESTYLE of only one popup, this is not good
  // we will do this in the mail popup component or implement a new one
  // this is here to complicated

  // temporary removed

  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 40.5em !important;
  // min-width: 22.5em;
  // padding: 2em;
  // background: csp-color(bg, base);

  // border-radius: 0.5em;
  // border: 1px solid csp-color(border, base);
  // box-shadow: 0px 6px 8px 0px rgba(55, 70, 73, 0.32), 0px 1px 1px 0px rgba(55, 70, 73, 0.8);

  // .popupFooter {
  //   display: flex;
  //   padding-top: 2.5em;
  //   justify-content: center;
  //   align-items: center;
  //   align-content: center;
  //   gap: csp-margin(l);
  //   align-self: stretch;
  //   flex-wrap: wrap;
  // }

  // .divider {
  //   display: flex;
  //   width: 0.0625em;
  //   height: 1.5em;
  //   flex-direction: column;
  //   background: csp-color(neutral-50, base);
  // }

  // .button {
  //   display: flex;
  //   height: 2em;
  //   padding: csp-padding(m);
  //   justify-content: center;
  //   align-items: center;
  //   gap: csp-margin(xs);
  //   font-size: csp-font(xs);
  // }

  // .button:disabled {
  //   background: csp-color(neutral-50, base);
  //   border-color: csp-color(neutral-50, base);
  //   color: csp-color(neutral-100, base);
  // }

  // .button > svg > path {
  //   fill: currentColor;
  // }

  // .inputCustomName {
  //   display: flex;
  //   width: 22.5em;
  //   max-width: 22.5em;
  //   flex-direction: column;
  //   gap: 0.5em;

  //   & > span {
  //     font-size: csp-font-size(xs);
  //     font-weight: 400;
  //   }

  //   & input {
  //     border-color: csp-color(border, base);
  //   }

  //   & input::placeholder {
  //     font-style: normal;
  //     font-size: csp-font-size(s);
  //   }
  // }

  // .popupBody {
  //   gap: 1.5em;
  //   align-items: center;
  // }

  // .helper {
  //   display: flex;
  //   flex-direction: row;
  //   border-radius: 1em;
  //   background: csp-color(bg, light);

  //   > div {
  //     display: flex;
  //     align-items: center;
  //     border-radius: 2em;
  //     border: 4px solid csp-color(bg, base);
  //     background: csp-color(bg, base);
  //     margin: auto csp-margin(s) auto;
  //   }

  //   > div > svg {
  //     min-width: 1.5em;
  //     color: csp-color(brand-primary-500, base);
  //   }

  //   > span {
  //     font-size: csp-font-size(s);
  //     font-weight: 400;
  //     color: csp-color(fg, base);
  //     margin-top: csp-margin(xs);
  //     margin-bottom: csp-margin(xs);
  //   }
  // }

  // .popupHeader {
  //   border-bottom: none;
  // }

  // .popupTitle {
  //   font-size: csp-font-size(l);
  //   font-weight: 700;
  //   margin-bottom: csp-margin(m);
  //   text-transform: initial;
  //   color: csp-color(fg, base);
  // }

  // @include csp-breakpoint-up(lg) {
  //   width: unset;
  // }

  // .submitError {
  //   padding-left: 2px;
  //   color: csp-color(red);
  //   border-color: csp-color(red);
  // }
}
