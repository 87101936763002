@import '../../../../styles/_imports';

.popup {
  max-width: 60vw;
  max-height: unset;

  .popupBody {
    > label,
    > div {
      gap: 0.5em !important;
      @include csp-breakpoint-up(md) {
        gap: 2em !important;
      }

      > div:first-child {
        @include csp-breakpoint-up(md) {
          min-width: 100px !important;
        }
      }
    }
  }

  .editEmail {
    @include csp-ellipsis();
    > div {
      @include csp-ellipsis();
    }
  }

  .passwordRow {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;

    .info {
      color: csp-color(primary, dark);
      display: table;
      max-width: 350px;
      span {
        padding-left: 0.5em;
        display: table-cell;
      }
    }

    .reset {
      color: csp-color(primary);
      display: flex;
      flex-direction: row;
      max-width: 350px;
      cursor: pointer;
      span {
        padding-left: 0.5em;
      }
    }
  }

  .rolesRow {
    overflow: hidden;
    .rolesWrapper {
      @include csp-vstack($align: flex-start);
      max-width: 99%;

      .roles {
        @include csp-vstack($align: flex-start);
        flex: 1 1;
        background-color: csp-color(bg-invert, light);
        border-radius: 20px;
        width: 99%;
        max-width: 99%;
        padding: csp-padding(m);
        margin: 5px 10px 10px 2px;
        gap: csp-margin(xs);
        max-height: 200px;
        overflow: scroll;

        &.hasValidationError {
          border: 1px solid csp-color(red);

          > label > div > input {
            border-color: csp-color(red);
          }
        }
      }

      .submitError {
        max-width: 350px;
      }
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }

  .infoIco {
    margin-left: csp-margin(xxs);
    cursor: pointer;
    svg {
      @include csp-fix-size(16px);
    }
    svg path {
      fill: csp-color(brand-primary-500, base);
    }
  }
}
