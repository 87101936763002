@import '../../../../styles/_imports';

.diagram {
  height: 100%;
  min-height: 50vh;
}

.diagramContainer {
  min-height: 50vh;
}

.roleHilighted {
  outline: 1px solid var(--col-primary-500) !important;
  // background-color: red !important;
}

.tabContent {
  padding: 0 0 var(--space-400) 0 !important;
  background-color: white;

  // var(--space-400)
}
