@import '../styles/_imports';

.root {
  // background-color: red;
  :global(.tagSelectPref__control) {
    border-radius: 2em;
    background-color: csp-color(bg-invert, light);
    border: none;
  }

  :global(.tagSelectPref__menu) {
    z-index: 9999;
  }

  .control {
    min-height: auto;
    line-height: 20px;
  }

  .searchControlWrapper {
    @include csp-hstack($align: center, $justify: space-between);
    width: 100%;

    .searchControlIco {
      padding-right: csp-padding(s);
      @include csp-vstack($align: center);

      svg {
        @include csp-fix-size(12px);
      }

      // align-self: flex-end;
    }
  }
}

.defaultOpt {
  @include csp-vstack();
  > b {
    //
  }
  > i {
    font-size: csp-font-size(s);
  }
}

.selectedTagArea {
  @include csp-vstack();
  .items {
    @include csp-hstack();
    @include csp-padding($all: xs);
    gap: csp-margin(s);
    flex-wrap: wrap;
    margin-top: csp-margin(xs);
    border-radius: 0.5em;
    background-color: csp-color(bg, dark);
    border: csp-border-default();
    min-height: 30px;
  }
  .total {
    margin-left: auto;
    font-size: csp-font-size(xs);
    color: csp-color(fg, light);
  }
}

.selectedTag {
  @include csp-hstack($align: center, $justify: space-between);
  font-size: csp-font-size(s);
  gap: csp-margin(xs);
  border: csp-border-default();
  border-radius: 1em;
  width: fit-content;
  @include csp-padding($horizontal: xs);
  background-color: csp-color(bg);
  box-shadow: #ced3d4 0px 0px 3px 1px;
  .label {
    @include csp-hstack($align: center);
  }
  .ico {
    > svg,
    img {
      @include csp-fix-size(18px);
    }
  }
  .close {
    @include csp-hstack($align: center);
    cursor: pointer;
    > svg,
    img {
      @include csp-fix-size(18px);
    }
    > svg path {
      stroke: csp-color(info);
    }
  }
}
