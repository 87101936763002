@import '../../styles/imports';

.root {
  .btnS,
  .ocppMsgLbl,
  .chBoxIdInput {
    @include csp-margin($top: s);
  }

  .selectOcppTemplate,
  .ocppAction {
    @include csp-margin($top: l);
  }

  .ocppMsgInput {
    min-height: csp-font-size(xxxl);
  }
}
