@import '../../../styles/_imports';

.toolbar {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 220px !important;
  background-color: white;
  border: solid 1px;
  z-index: 1000;
  .list {
    display: block;
    list-style: none;
    @include csp-vstack();
    padding: 0;
    li {
      padding: csp-padding(xs);
      border-bottom: black solid 1px;
      &:hover {
        background-color: csp-color(primary);
        color: csp-color(fg-invert);
        cursor: pointer;
      }
    }
  }

  > svg {
    display: none;
  }
  &.collapsed {
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 50%;
    bottom: 10px;
    left: 10px;
    padding: csp-padding(xs);
    cursor: pointer;
    .list {
      display: none;
    }
    > svg {
      display: block;
      @include csp-fix-size($size: 20px);
    }
  }
}
.pickExample {
}
