@import '../styles/_imports';

.titleArea {
  @include csp-hstack($justify: center);
  padding: csp-padding(m);

  > div {
    margin-left: csp-margin(m);
    padding-right: csp-padding(m);
    text-align: center;
    border-right: 1px solid csp-color(brand-primary-200, base);
  }

  > div:first-child {
    padding-left: unset;
  }

  > div:last-child {
    border: none;
    padding: 0.1em 0.3em 0.1em 0.3em;
    border-radius: 10px;
  }
}

.roleName {
  > span {
    border: 2px solid csp-color(brand-primary-300, base);
    padding: 2px 8px 2px 8px;
    border-radius: 8px;
  }
}

.content {
  width: 50vw;
  min-height: 450px;
}

.hidden {
  visibility: hidden;
}
