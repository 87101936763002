@import '../styles/_imports';

.errorLabel {
  display: flex !important;
  padding-top: csp-padding(xxs);
  color: csp-color(red);
  font-size: 12px;
  @include csp-hstack($align: center);
  gap: csp-margin(xs);
  .warnIco > svg {
    @include csp-fix-size(18px);
  }
}
.root {
  // @include csp-padding($horizontal: xs);

  $inputPaddingTB: csp-padding(xxs);
  $inputPaddingLR: csp-padding(xs);
  $inputBorderWidth: 1px;
  position: relative;

  > .lblWrapper {
    display: block;
    //text-transform: uppercase;
    font-size: csp-font-size(xs);
    color: csp-color(fg, light);
    // width: calc(100% - #{$inputPaddingLR * 3});
    width: 100%;
    position: absolute;
    height: 18px;
    top: -18px;
    > span {
      display: inline-block;
      position: absolute;
      white-space: nowrap;
    }
  }
  > .lblWrapper.lblError {
    top: auto;
  }

  &.v2Labels > .lblWrapper {
    position: relative;
    top: auto;
    margin-bottom: csp-margin(xxs);
    span {
      position: relative;
    }
  }

  > input {
    //border-radius: 3px;
    border-radius: 2em;
    border: $inputBorderWidth solid #cccccc;
    padding: $inputPaddingTB $inputPaddingLR;
    width: 100%;

    // @include csp-padding($left: xs, $right: xs, $top: xxs, $bottom: xxs);
    outline: none !important;
    color: csp-color(fg);
    font-weight: 400;

    // border-image-slice: 1;
    // background-image: linear-gradient(transparent, transparent);
    background-color: csp-color(bg-invert, light);
    border-color: csp-color(bg-invert, light);

    &::placeholder {
      font-weight: 200;
      font-style: italic;
      font-size: csp-font-size(xs);
      color: csp-color(fg, light);
    }
  }

  &.hasValidationError {
    // > .lblWrapper > span {
    //   // left: 100%;
    //   // transform: translateX(-100%);

    //   // color: csp-color(red);
    // }
    > input {
      border-color: csp-color(red);
    }
  }

  &:focus-within {
    > input {
      // $focusedInputBorderAdd: 2px;
      // border-width: $inputBorderWidth + $focusedInputBorderAdd;
      color: csp-color(fg, dark);
      // border-image-source: csp-gradient();

      // padding: calc(#{$inputPaddingTB} - #{$focusedInputBorderAdd}) calc(#{$inputPaddingLR} - #{$focusedInputBorderAdd});
    }
    > .lblWrapper > span {
      color: csp-color(accent);
      // background: csp-gradient();
      // background-clip: text;
      //-webkit-text-fill-color: transparent;
    }
  }
}
