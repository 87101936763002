@import '../../styles/_imports';

.root {
  @include csp-vstack();

  .header {
    z-index: 2;
    @include csp-hstack($align: center);
    @include csp-padding($all: m);

    gap: csp-margin(m);

    background-color: csp-color(bg-invert, lighten);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .expander {
      @include csp-btn();
      @include csp-fix-size(24px);
      padding: 0;
      > svg {
        @include csp-fix-size(24px);
      }
      justify-content: end;
    }
    .headerInner {
      flex: 1;
      @include csp-ellipsis();
    }
  }
  .content {
    // don't set the z-index otherwise also nested children will be behind 'header' even if they have a higher z-index than header
    // z-index: 1;
    background-color: csp-color(bg-invert, lighten);
    padding: csp-padding(m);

    border-radius: 10px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: none;

    &.expanded {
      display: block;
    }
  }
}
