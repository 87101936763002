@import '../../../styles/_imports';

.totalSumBar {
  min-height: 56px;
}

.barChartXLabel {
}

.gesamt {
  font-size: var(--fs-m);
}
