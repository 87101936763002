@import '../../../../styles/_imports';

.popup {
  width: 60vw;
  min-height: 70vh;
  max-height: 100%;

  .fullNameLbl {
    @include csp-ellipsis();
    max-width: 40%;
  }

  .roleNameContainer {
    @include csp-hstack($align: center);
    width: max-content;
    .infoIco {
      width: min-content;
      cursor: pointer;
      svg {
        @include csp-fix-size(16px);
      }
      svg path {
        fill: csp-color(brand-primary-500, base);
      }
    }
    .inputArea {
      width: 100%;
      .inputField {
        width: 100%;
      }
    }
  }

  .deleteRole {
    @include csp-hstack();
    gap: csp-margin(s);
    color: csp-color(red);
    cursor: pointer;
    margin-bottom: csp-margin(s);
    svg path {
      fill: csp-color(red);
    }
  }

  .footer {
    background-color: csp-color(bg-invert, light);
  }

  .actions {
    justify-content: right !important;

    button {
      max-width: 200px;
    }
  }

  .submitError {
    padding-left: 2px;
    color: csp-color(red);
    border-color: csp-color(red);
  }
}
