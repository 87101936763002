@import '../styles/_imports';

.box {
  // background-color: lime;

  &.txt-wrap {
    text-wrap: balance;
  }

  @each $name, $val in $_csp-spaces {
    &.gap-#{$name} {
      gap: csp-margin($name);
    }
  }
  &:hover {
    &.hover-pointer {
      cursor: pointer;
    }
    &.hover-underline {
      text-decoration: underline;
    }
  }
  .pointer {
    cursor: pointer;
  }

  &.ellipses {
    @include csp-ellipsis();
  }
  &.underline {
    text-decoration: underline;
  }

  // kind prop
  @include gen-responsive-box(
    (
      vgrid: (
        display: grid,
        grid-template-rows: repeat(auto-fit, minmax(0, 1fr)),
      ),
      hgrid: (
        display: grid,
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr)),
      ),
      vflex: (
        display: flex,
        flex-direction: column,
      ),
      hflex: (
        display: flex,
        flex-direction: row,
      ),
    )
  );

  &.justify-center {
    justify-content: center;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-space-evenly {
    justify-content: space-evenly;
  }
  &.justify-stretch {
    justify-content: stretch;
  }
  &.justify-flex-start {
    justify-content: flex-start;
  }
  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.txt-center {
    text-align: center;
  }

  &.align-center {
    align-items: center;
  }
  &.align-flex-start {
    align-items: flex-start;
  }
  &.align-flex-end {
    align-items: flex-end;
  }
  &.align-stretch {
    align-items: stretch;
  }

  &.flex-grow-1 {
    flex-grow: 1;
  }
  &.flex-grow-2 {
    flex-grow: 2;
  }
  &.flex-grow-3 {
    flex-grow: 3;
  }

  @each $k, $v in $_csp-breakpoints {
  }

  $__fontSizes: default, 5xl, 4xl, 3xl, 2xl, xl, l, m, s, xs, 2xs;
  @each $size in $__fontSizes {
    &.fs-#{$size} {
      font-size: var(--fs-#{$size});
    }
  }
  @each $k, $v in $_csp-breakpoints {
    @each $size in $__fontSizes {
      @media (min-width: map-get($map: $_csp-breakpoints, $key: $k)) {
        $clsName: 'fs-' + $k + '-resp-' + $size;
        &.#{$clsName} {
          font-size: var(--fs-#{$size});
        }
      }
    }
  }

  $__lineHeights: lh-default, lh-5xl, lh-4xl, lh-3xl, lh-2xl, lh-xl, lh-l, lh-m, lh-s, lh-xs, lh-2xs, lh-3xs;
  @each $size in $__lineHeights {
    &.#{$size} {
      line-height: var(--#{$size});
    }
  }

  $__fontWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
  @each $val in $__fontWeights {
    &.fw-#{$val} {
      font-weight: $val;
    }
  }

  $cellSizes: 1, 2, 3, 4, 5, 6, 7, 8;
  @each $size in $cellSizes {
    &.cell-#{$size} {
      grid-column-end: span $size;
    }
  }
}

.divider {
  background-color: var(--col-primary-neutral-050);
  &.v {
    min-width: 1px;
    max-width: 1px;
    min-height: 100%;
    align-self: stretch;
  }
  &.h {
    min-height: 1px;
    max-height: 1px;
    min-width: 100%;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.skeleton {
  background-color: var(--col-brand-neutral-000);
  margin-bottom: var(--space-200);

  &.accordion {
    svg {
      width: 100%;
    }
    // max-height: 100px;
  }
  &.chart {
    width: auto;
    flex: 1;
    svg {
      width: 100%;
    }
  }
  // height: fit-content;

  // .row{
  //   height: fit-content;
  // }
  // .col{
  //   height: fit-content;
  // }

  // .shimmer{
  //   --base-col1: var(--col-primary-neutral-050);
  //   --base-col2: color-mix(in srgb, var(--col-primary-neutral-050) 80%, transparent);

  //   animation-duration: 1.25s;
  //   animation-fill-mode: forwards;
  //   animation-iteration-count: infinite;
  //   animation-name: placeHolderShimmer;
  //   animation-timing-function: linear;
  //   background: darkgray;
  //   background: linear-gradient(to right, var(--base-col1) 10%, var(--base-col2) 18%, var(--base-col1) 33%);
  //   background-size: 800px 104px;
  //   height: 30px;
  //   position: relative;

  //   &:nth-child(1){
  //     animation-delay: .8s;
  //   }
  //   &:nth-child(2){
  //     animation-delay: .8s;
  //   }
  // }
}

.wrap {
  flex-wrap: wrap;
}

.contentBox {
  background-color: var(--col-primary-white);
  padding: var(--space-500);
  border: solid 1px var(--col-brand-neutral-900);
  border-radius: 8px;
}
