@import '../../styles/_imports';

.root {
  .charts {
    background-color: var(--col-primary-white);
    padding: var(--space-500);
    border: solid 1px var(--col-brand-neutral-900);
    border-radius: 8px;
  }

  .plugSelect {
    width: 180px;
  }

  .totalEnergyConsumption {
    background-color: var(--col-brand-neutral-050);
    padding: var(--space-300);
    text-align: center;
    font-weight: 500;
  }

  // padding-bottom: csp-padding(xxl);

  // .totalEnergyConsumptionPill {
  //   background-color: csp-color(primary, dark);
  //   color: csp-color(fg-invert);
  //   font-weight: bold;
  //   width: fit-content;
  //   border-radius: 1em;
  //   padding: csp-padding(xs) csp-padding(s);
  //   @include csp-margin($top: s, $left: auto, $right: auto);
  // }

  // .stationsOverview {
  //   background-color: csp-color(bg, base);
  // }

  // .cpListContainer {
  //   width: 100%;
  // }

  // .selectedTags {
  //   @include csp-hstack();
  //   flex-wrap: wrap;
  //   gap: csp-padding(xs);
  //   padding-bottom: csp-padding(s);

  //   .appliedTag {
  //     background-color: csp-color(bg-inactive, base);
  //     color: csp-color(fg-inactive, base);

  //     svg path {
  //       stroke: csp-color(fg-inactive);
  //     }
  //   }
  // }

  // .tabContent {
  //   border-left: 1px solid csp-color(bg-invert, base);
  //   border-right: 1px solid csp-color(bg-invert, base);
  //   padding-left: csp-margin(xs);
  //   padding-right: csp-margin(xs);
  //   margin-top: -#{csp-margin(l)};
  // }

  // .pagination {
  //   box-shadow: none;
  //   border-radius: 0;
  //   border-bottom: 1px solid csp-color(bg-invert, base);
  // }
}
