@import '../../../styles/imports';

.filterArea {
  @include csp-hstack();
  gap: csp-margin(s);
  flex-wrap: wrap;
  flex-direction: column;
}

.filters {
  @include csp-hstack();
  gap: csp-margin(s);
  flex-wrap: wrap;
  flex-direction: row !important;
}

.selectPages {
  min-width: 150px;
  max-width: 150px;
}

.selectActions {
  min-width: 200px;
  max-width: 200px;
}

.clientOpt {
  @include csp-vstack();
  max-width: 100%;

  i {
    flex: 1;
    font-style: normal;
    color: csp-color(fg, light);
    font-size: csp-font-size(xs);
  }
  b {
    @include csp-ellipsis();
    flex: 1;
    font-weight: 600;
    font-size: csp-font-size(s);
  }
}

.datePicker {
  @include csp-breakpoint-up(sm) {
    margin-left: auto;
  }
}
