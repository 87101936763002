@import '../../styles/_imports';

.root {
  width: 202px;
  // background-color: red;
  :global(.tagSelectPref__control) {
    border-radius: 8px;
    background-color: var(--col-primary-white);
    border: solid 1px var(--col-brand-neutral-300);
  }

  :global(.tagSelectPref__menu) {
    z-index: 9999;
  }

  .control {
    min-height: auto;
    font-size: 14px;
    line-height: 16px;
  }
}

.tagArea {
  border-radius: 8px;
  padding: var(--space-300);
  background-color: var(--col-primary-neutral-010);
  border: solid 1px var(--col-primary-neutral-050);
}

.selectedTag {
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
  border-radius: 16px;
  border: solid 1px var(--col-primary-neutral-050);
  padding: 1px var(--space-200);
  background-color: var(--col-primary-white);
}
// .selectedTagArea {
//   @include csp-vstack();
//   .items {
//     @include csp-hstack();
//     @include csp-padding($all: xs);
//     gap: csp-margin(s);
//     flex-wrap: wrap;
//     margin-top: csp-margin(xs);
//     border-radius: 0.5em;
//     background-color: csp-color(bg, dark);
//     border: csp-border-default();
//     min-height: 30px;
//   }
//   .total {
//     margin-left: auto;
//     font-size: csp-font-size(xs);
//     color: csp-color(fg, light);
//   }
// }

// .selectedTag {
//   @include csp-hstack($align: center, $justify: space-between);
//   font-size: csp-font-size(s);
//   gap: csp-margin(xs);
//   border: csp-border-default();
//   border-radius: 1em;
//   width: fit-content;
//   @include csp-padding($horizontal: xs);
//   background-color: csp-color(bg);
//   box-shadow: #ced3d4 0px 0px 3px 1px;
//   .label {
//     @include csp-hstack($align: center);
//   }
//   .ico {
//     > svg,
//     img {
//       @include csp-fix-size(18px);
//     }
//   }
//   .close {
//     @include csp-hstack($align: center);
//     cursor: pointer;
//     > svg,
//     img {
//       @include csp-fix-size(18px);
//     }
//     > svg path {
//       stroke: csp-color(info);
//     }
//   }
// }
