@import '../styles/_imports';

.root {
  @include csp-hstack($align: center);

  &.disabled {
    > input {
      accent-color: csp-color(bg-invert);
      border: solid 1px csp-color(bg-invert);
    }
    > label {
      color: csp-color(fg, lighten);
    }
  }

  > input {
    // width: 1em;
    // height: 1em;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    accent-color: csp-color(primary);
    border: solid 1px csp-color(primary);
    appearance: none;
    /* Safari support */
    -webkit-appearance: none;

    &:checked {
      appearance: auto;
    }

    // margin-top: 10px;
  }

  // .inputWrapper{

  // }

  > span {
    padding-left: 0.5em;
  }
}
