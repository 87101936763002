@import '../styles/_imports';

.root {
  @include csp-button();

  background-color: csp-color(primary);
  color: csp-color(fg-invert);
  height: 32px;

  padding: csp-padding(xs);
  border-radius: 2em;
  font-size: csp-font-size(xs);
  min-width: 80px;
  flex-shrink: 0;

  white-space: nowrap;
  @include csp-hstack($align: center, $justify: space-between);

  > span {
    @include csp-ellipsis();
    flex: 1;
  }
  > svg {
    @include csp-fix-size(16px);
    path {
      stroke: csp-color(fg-invert);
    }
  }

  &.selected {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  // min-width: ;
}
