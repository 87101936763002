@import '../styles/_imports';

.root {
  height: 32px;
  gap: csp-margin(xs);
  padding-inline: csp-padding(s);
  display: flex;
  flex-direction: row;
  @include csp-hstack($align: center);

  // border: csp-border-default();
  // border-color: #cccccc;
  border-radius: 2em;
  // background-color: csp-color(bg, darken);
  background-color: csp-color(bg-invert, light);
  cursor: pointer;
  input {
    cursor: pointer;
  }

  // &:focus-within{
  //   outline: -webkit-focus-ring-color auto 1px;
  // }

  > svg {
    @include csp-fix-size(18px);
  }
  // background-color: csp-color(bg, darken);
  // border-radius: 2em;

  // :global(.DatePicker__input){
  //   background-color: csp-color(bg, darken);
  // }

  .customInput {
    padding: csp-padding(s) / 2 csp-padding(s);
  }
}

.dateRangePickerWrapper {
  // margin-left: csp-margin(xs);
  > input {
    font-size: csp-font-size(s);
    color: csp-color(fg, light);
    min-width: 230px;
    flex: 1;
    height: 100%;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
    padding-left: 0;
    // font-weight: 600;

    // padding: 0;
    // text-decoration: underline;
    // text-decoration-style: dashed;
    text-align: left;
  }
}

.clearIcon {
  display: flex;
  align-items: center;
  :hover {
    color: csp-color(hl-hover);
  }
  color: csp-color(hl);
}

.dateRangePickerStart,
.dateRangePickerEnd {
  background-color: csp-color(primary) !important;
}
.dateRangePickerBetween {
  background-color: csp-color(primary, light) !important;
  color: csp-color(primary, dark) !important;
}
