@import '../../styles/_imports';

.root {
  border-radius: 4px;
  padding: var(--space-100) var(--space-200);
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
}

.dotBadge {
  border-radius: 8px;
  padding: 0 var(--space-200);
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: solid 1px var(--col-primary-neutral-030);
}
