@import '../styles/_imports';

.root {
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--col-primary-white);
  z-index: 10;

  @include csp-vstack($align: center);
  // > div {
  //   @include csp-vstack($align: center);
  // }

  .logo {
    max-width: 200px;
    // @include csp-margin($top: l);
    margin-top: 20vh;
  }
  .title {
    @include csp-margin($top: s);
    color: csp-color(primary);
  }

  .loginErr {
    color: csp-color(red);
    margin-top: csp-margin(l);
    @include csp-vstack();
    min-width: 400px;
    max-width: 400px;
    // font-size: csp-font-size(l);
    // padding: csp-padding(m);
    @include csp-padding($horizontal: m, $vertical: l);
    background-color: csp-color(red, light);
    border: solid 1px csp-color(red);
    border-radius: 10px;
    .message {
      margin-top: csp-margin(m);
      margin-bottom: csp-margin(m);
      font-size: csp-font-size(s);
      font-weight: bold;
    }
    .code {
      font-weight: thin;
      font-family: monospace;
    }

    > button {
      margin-top: csp-margin(m);
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .profileFetching {
    min-height: 100vh;
    min-width: 100vw;
    background-color: var(--col-primary-white);

    // color: csp-color(primary, light);
    // margin-top: csp-margin(xxl);
    // font-size: csp-font-size(l);
    // text-transform: uppercase;

    // .progress {
    //   width: 100%;
    //   height: 10px;
    //   margin-left: auto;
    //   margin-right: auto;
    //   margin-top: csp-margin(s);
    //   border: 1px solid csp-color(primary);
    //   box-shadow: csp-color(primary, light) 0px 0px 3px 0px;
    //   border-radius: 4px;
    //   overflow: hidden;
    //   // csp-color(primary, dark) 0px 5px 2px -5px;

    //   > div {
    //     background-color: csp-color(primary, light);
    //     height: 100%;
    //     width: 0%;
    //     margin-left: auto;
    //     margin-right: auto;
    //     animation: pending 2s linear infinite alternate forwards;
    //   }
    // }
  }
}

@keyframes pending {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
