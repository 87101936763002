@import '../../styles/_imports';

.root {
  border-radius: 2em;
  // overflow: hidden;
  .option {
    &.focused {
    }
  }

  .displayHolder {
    @include csp-ellipsis();
    padding-left: csp-padding(xs);
  }
}

:global(.singleSelectPrefix__control) {
  flex-wrap: nowrap !important;
  background-color: csp-color(bg-invert, light) !important;
  border: none !important;
  border-radius: 2em !important;
  min-height: 32px !important;
}
:global(.singleSelectPrefix__control.singleSelectPrefix__control--menu-is-open .singleSelectPrefix__dropdown-indicator) {
  transform: rotate(180deg);
}

:global(.singleSelectPrefix__value-container) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
:global(.singleSelectPrefix__indicator-separator) {
  display: none;
}
:global(.singleSelectPrefix__input-container) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
:global(.singleSelectPrefix__dropdown-indicator) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
:global(.singleSelectPrefix__placeholder) {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  font-style: normal;
  color: csp-color(fg, light);
}
