@import '../../styles/_imports';

.root {
  @include csp-vstack();
  gap: csp-margin(m);

  .pill {
    min-width: unset;
    width: 42px;
    height: 16px;
  }

  .statsRow {
    @include csp-vstack();
    &.hide {
      visibility: hidden;
    }
  }

  .titleValue {
    @include csp-hstack($justify: space-between);
    gap: csp-padding(s);

    > div {
      // flex: 1;
      color: csp-color(fg, light);
    }

    .blurValue {
      filter: blur(2px);
      -webkit-filter: blur(2px);
    }
  }

  .tooltip {
    max-width: 400px;
    opacity: 1 !important;
  }
}
