@import '../styles/_imports';

.root {
  @include csp-make-page-inner-container();
  @include csp-vstack;

  .title {
    font-size: csp-font-size(m);
    color: csp-color(fg, light);
  }
  .body {
    // background-color: csp-color(bg);
    // border: csp-border-default();
    @include csp-vstack();
    position: relative;

    &.fullHeight,
    .fullHeight {
      flex: 1;
    }

    .ctxTrigger {
      // @include csp-padding($vertical: xxs, $horizontal: m);
      @include csp-vstack(center, center);
      padding: 0;
      right: 0;
      height: 28px;
      width: 38px;
      border: none;
      position: absolute;
      background-color: transparent;

      > svg {
        height: 100%;
        width: 100%;
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        > svg {
          transform: rotate(90deg) scale(1.3);
        }
      }
    }
  }

  .row {
    padding: csp-padding(m) csp-padding(s);
    padding-bottom: #{csp-padding(m) - csp-margin(m)};
    @include csp-hstack();

    border-bottom: csp-border-default();
    padding-bottom: csp-margin(m);

    &:last-child,
    &.noSep {
      padding-bottom: csp-padding(s);
      border-bottom: none;
    }
    .inner {
      @include csp-hstack($align: center);
      column-gap: csp-margin(xs);
      flex: 1;
    }

    .ctxTrigger {
      align-self: center;
      .icoDown {
        @include csp-fix-size(12px);
        transform: rotate(90deg);
        // path{
        // fill: csp-color(accent);
        // }
      }
    }
  }
  .col {
    @include csp-hstack($align: center);
    column-gap: csp-margin(xs);
    flex: 1;
  }

  .prop {
    @include csp-vstack;
    // margin-left: csp-margin(xs);
  }
  .val {
    font-weight: bold;
  }
  .lbl {
    color: csp-color(fg, light);
    font-weight: 300;
  }
}

.ctxMenuItem {
  :global(.react-contexify__item__content) {
    color: csp-color(primary);
    font-weight: 500;
    > svg {
      @include csp-fix-size(20px);
      margin-right: csp-margin(s);
    }
  }

  &:hover {
    :global(.react-contexify__item__content) {
      color: csp-color(fg-invert);
      background-color: csp-color(primary);
      > svg path {
        stroke: csp-color(fg-invert);
      }
    }
  }
}
