@import '../styles/_imports';

.root {
  @include csp-vstack();
  min-width: calc(100vw - 60px);
  max-height: 90vh;

  form {
    @include csp-vstack();
  }

  @include csp-breakpoint-up(sm) {
    min-width: 360px;
  }

  @include csp-breakpoint-up(md) {
    min-width: 420px;
    // min-width: 520px;
  }
}

.header {
  @include csp-hstack($align: center, $justify: space-between);
  padding: csp-padding(xs);
  // margin-bottom: csp-margin(m);
  border-bottom: csp-border-default();
  box-shadow: #0000001a 0px 5px 2px -5px;

  > button {
    border: none;
    background-color: transparent;
  }

  > .title {
    text-transform: uppercase;
    flex: 1;
    text-align: center;
    color: csp-color(fg, dark);
    font-weight: bold;
    //padding-left: csp-padding(xs);

    // @include csp-breakpoint-up(md){
    //   padding-left: calc(#{csp-padding(l)} + #{csp-padding(xs)});
    // }
  }
}

.body {
  // padding: csp-padding(m);
  @include csp-padding($horizontal: m);
  min-height: 150px;
  @include csp-vstack();
  flex-grow: 1;
  flex-shrink: 0;

  .row {
    @include csp-hstack($align: center, $justify: space-between);
    // @include csp-padding($bottom: l, $top: l);
    padding-top: csp-padding(m);
    padding-bottom: csp-padding(m);
    //border-bottom: csp-border-default();
    gap: csp-margin(s);
    position: relative;

    @include csp-breakpoint-up(md) {
      @include csp-padding($horizontal: l);
      gap: csp-margin(xl);
    }

    &:first-child {
      padding-top: csp-padding(l);
    }

    &:last-child {
      border-bottom: none;
    }

    .lbl {
      color: csp-color(fg, dark);
      font-weight: 500;
      min-width: 100px;
      font-size: csp-font-size(s);

      @include csp-breakpoint-up(md) {
        font-size: csp-font-size(m);
      }
    }

    // &:focus-within{
    //   // background-color: lime;
    //   .lbl{
    //     background: csp-gradient();
    //     background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //   }

    // }

    // .lblValidationErr{
    //   position: absolute;
    //   right: csp-margin(l);
    //   margin-right: csp-margin(l);
    //   margin-top: 52px;
    //   font-size: csp-font-size(xs);
    //   // display: block;
    //   // width: 100%;
    // }
    .ctrl {
      @include csp-hstack();
      flex: 1;
      gap: csp-margin(xxs);

      @include csp-breakpoint-up(md) {
        gap: csp-margin(m);
      }

      // min-width: 240px;
      > input,
      > div,
      > label {
        // width: 120px;
        flex: 1;
      }
    }
  }
}

.footer {
  @include csp-vstack($align: stretch, $justify: flex-end);
  padding: csp-padding(m);
  gap: csp-padding(s);

  .additionalContent {
    display: inline;
    margin-left: csp-margin(s);
  }

  .actions {
    @include csp-hstack($align: center, $justify: space-between);
    padding-right: csp-padding(xxs);
    padding-bottom: csp-padding(xs);
    padding-top: csp-padding(xs);

    > button {
      flex: 1;
      margin-left: csp-margin(s);
      margin-right: csp-margin(s);
    }
  }
}

:global {
  .edit-popup-content.popup-content {
    // margin-right: 0px !important;
    // margin-top: 0px !important;
    width: auto !important;
    max-height: 100%;
    overflow-y: auto;
  }

  .no-scroll-content.edit-popup-content.popup-content {
    overflow-y: visible;
  }
}

.step {
  .body {
    max-width: 360px;

    @include csp-padding($top: m, $bottom: m);
    @include csp-vstack($align: center, $justify: center);
    gap: csp-margin(l);

    label {
      text-align: center;
    }
  }

  .actions {
    @include csp-padding($top: m, $bottom: m);
    @include csp-hstack($align: center, $justify: center);
    gap: csp-margin(s);

    > button {
      min-width: 140px;
    }
  }

  .step {
    svg {
      @include csp-fix-size(60px);
    }
  }

  .progress,
  .success,
  .failed {
    margin-left: auto;
    margin-right: auto;

    label {
      font-weight: 600;
    }
  }

  .partial {
    svg path {
      fill: csp-color(orange);
    }
  }

  .progress {
    svg {
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      animation: rotating 2s linear infinite;
    }
  }

  .search {
    .selection {
      width: 280px;
      padding: 0 csp-padding(s);
    }

    .dropdown {
      width: 280px;

      :global(.tagSelectPref__value-container) {
        flex-wrap: nowrap;
      }

      :global(.tagSelectPref__multi-value__label) {
        padding: 0;
      }

      // .entityFilterTag {
      //   @include csp-padding($horizontal: xs);
      //   @include csp-hstack($align: center);
      //   color: csp-color(fg-invert);
      //   height: 100%;
      // }

      // .entityFilterOpt {
      //   @include csp-vstack();

      //   .type {
      //     font-size: csp-font-size(xs);
      //   }

      //   &.station .type {
      //     color: csp-color(accent);
      //   }

      //   &.card .type {
      //     color: csp-color(warn);
      //   }

      //   &.cardLbl .type {
      //     color: csp-color(lemonGreen);
      //   }

      //   &.cardCMT .type {
      //     color: csp-color(deepBrown);
      //   }

      //   &.rate .type {
      //     color: csp-color(primary);
      //   }
      // }
    }
  }

  .confirm,
  .search {
    margin-left: auto;
    margin-right: auto;

    .warn {
      color: csp-color(danger);
    }
  }
}
