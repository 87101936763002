@import '../styles/_imports';

.categoriesContainer {
  .categories {
    border: 1px solid grey;
    border-radius: 1em;
    padding-left: csp-padding(s);
    padding-right: csp-padding(s);
    @include csp-hstack($align: center);
    .scrollList {
      width: 100%;
      @include csp-hstack($align: center);
      overflow: hidden;
      white-space: nowrap;
      cursor: default;
      > div {
        padding-top: csp-padding(m);
        padding-bottom: csp-padding(m);
        @include csp-padding($horizontal: m);
        &:hover {
          font-weight: bold;
          border-bottom: 3px solid csp-color(brand-primary-500, base);
          text-decoration: underline;
        }
        cursor: pointer;
      }
      .selectedCategory {
        font-weight: bold;
        border-bottom: 3px solid csp-color(brand-primary-500, base);
      }
    }
    .scrollArrow {
      cursor: pointer;
      min-width: 24px;
      height: 24px;
    }
    .arrowLeft {
      margin-right: csp-margin(xxs);
      padding-right: csp-padding(xs);
      border-right: 1px solid black;
    }
    .arrowRight {
      margin-left: csp-margin(xxs);
      padding-left: csp-padding(xs);
      border-left: 1px solid black;
    }
    .grabbable {
      cursor: grab;
    }
  }

  .itemList {
    padding-top: csp-padding(l);
    padding-left: csp-padding(l);
    @include csp-hstack($justify: space-between);
    flex-wrap: wrap;
    :nth-child(odd) {
      padding-right: csp-padding(l);
    }
    .item {
      display: list-item;
    }
    .oneColumn {
      flex-basis: 100%;
    }
    .twoColumns {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}
